import React, { useCallback, useState } from "react";
import { Avatar, Flex, List, Select, Space, Typography } from "antd";
import { format } from "date-fns";
import { ArrowRightOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import {
  AppointmentStatus,
  useGetCustomersWithAppointmentsLazyQuery,
} from "../../../../../graphql/schema";

const CustomerSearch = () => {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState<string>("");
  const [fetchCustomers, { data, loading }] =
    useGetCustomersWithAppointmentsLazyQuery({
      fetchPolicy: "no-cache",
    });

  // Debounced fetch function
  const debouncedFetchCustomers = useCallback(
    debounce((value: string) => {
      if (value) {
        fetchCustomers({
          variables: {
            options: {
              itemsPerPage: 5,
              query: {
                fields: ["firstName", "lastName"],
                term: value,
              },
            },
          },
        });
      }
    }, 500), // Debounce time in milliseconds
    [fetchCustomers],
  );

  const handleSearch = (value: string) => {
    setSearchValue(value);
    debouncedFetchCustomers(value);
  };

  return (
    <Select
      showSearch
      value={searchValue}
      placeholder="Kunden suchen"
      onSearch={handleSearch}
      filterOption={false}
      style={{ minWidth: 400 }}
      dropdownStyle={{ maxHeight: 800 }}
      dropdownRender={() => (
        <Space
          direction="vertical"
          style={{
            padding: 10,
            maxHeight: 400,
            width: "100%",
            overflow: "auto",
          }}
        >
          <Typography.Text strong>Kunde</Typography.Text>
          <List
            dataSource={data?.customers?.items || []}
            renderItem={(customer) => (
              <Flex
                onClick={() => navigate(`/customer/${customer.id}`)}
                align="center"
                gap={10}
                style={{
                  cursor: "pointer",
                  marginLeft: 10,
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                <Avatar>{`${customer.firstName[0]}${customer.lastName[0]}`}</Avatar>
                <Typography.Text>{`${customer.firstName} ${customer.lastName}`}</Typography.Text>
              </Flex>
            )}
          />
          <Typography.Text strong>Termine</Typography.Text>
          <List
            dataSource={
              data?.customers?.items
                ?.flatMap((customer) => customer.appointments || [])
                .filter((appointment) =>
                  [
                    AppointmentStatus.New,
                    AppointmentStatus.Confirmed,
                    AppointmentStatus.CustomerArrived,
                    AppointmentStatus.Accomplished,
                    AppointmentStatus.Present,
                  ].includes(appointment.appointmentStatus),
                ) || []
            }
            renderItem={(appointment) => (
              <Flex
                gap={10}
                justify="space-between"
                align="center"
                style={{
                  cursor: "pointer",
                  marginLeft: 10,
                  marginTop: 10,
                  marginRight: 10,
                  marginBottom: 10,
                }}
                onClick={() => {
                  navigate(`/appointments`, {
                    state: {
                      id: appointment.id,
                      storeId: appointment?.store?.id,
                      date: appointment.dateFrom,
                      status: appointment.appointmentStatus,
                    },
                  });
                  window.location.reload();
                }}
              >
                <Flex align="center" gap={20}>
                  <Flex vertical align="center">
                    <Typography.Text>
                      {format(new Date(appointment.dateFrom), "dd")}
                    </Typography.Text>
                    <Typography.Text>
                      {format(new Date(appointment.dateFrom), "MMM")}
                    </Typography.Text>
                  </Flex>
                  <Typography.Text>
                    {appointment?.customers
                      ?.map(
                        (customer) =>
                          `${customer.firstName} ${customer.lastName}`,
                      )
                      .join(", ")}
                  </Typography.Text>
                </Flex>
                <ArrowRightOutlined />
              </Flex>
            )}
          />
        </Space>
      )}
    />
  );
};

export default CustomerSearch;
