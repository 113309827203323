import {
  AppointmentStatus,
  CalendarAppointmentFragment,
} from "../../../../graphql/schema";

const getBackgroundColor = (status: AppointmentStatus) => {
  if (status === AppointmentStatus.Cancelled) return "#ff4040";
  if (status === AppointmentStatus.CancelledByCustomer) return "#ff4040";
  if (status === AppointmentStatus.Absent) return "#343a40";
  if (status === AppointmentStatus.RescheduledByKalialab) return "#696969";
  if (status === AppointmentStatus.New) return "#3399ff";
  if (status === AppointmentStatus.NoShow) return "#adb5bd";
  if (status === AppointmentStatus.Confirmed) return "#FED049";
  if (status === AppointmentStatus.ConfirmedWithVirtualPractitioner)
    return "#F39C12";
  if (status === AppointmentStatus.CustomerArrived) return "#00cc00";
  if (status === AppointmentStatus.Accomplished) return "#00DFA2";
  return "#3399ff";
};

interface EventInput {
  title: string;
  id: string;
  start: Date;
  end: Date;
  status: AppointmentStatus;
  practitioners: number[];
  customers?: any[];
  products: any[];
  message: string;
  internalNotice?: string;
  resourceIds: string[];
}

const createEvent = ({
  id,
  start,
  end,
  resourceIds,
  status,
  products,
  practitioners,
  customers,
  message,
  internalNotice,
}: EventInput) => {
  return {
    title: "",
    id,
    start,
    end,
    backgroundColor: getBackgroundColor(status),
    extendedProps: {
      practitioners: status === AppointmentStatus.Absent ? practitioners : [],
      customers,
      products,
      message,
      internalNotice,
    },
    resourceIds: resourceIds.length ? resourceIds : ["0"],
    borderColor: getBackgroundColor(status),
  };
};

const getPractitionerIdsInAppointment = (appointment: any) => {
  const practitionerIds: number[] = [];
  appointment?.practitioners?.forEach((practitioner: any) => {
    practitionerIds.push(practitioner.id);
  });
  return Array.from(new Set(practitionerIds));
};

export const getEventsByAppointments = (
  appointments: CalendarAppointmentFragment[],
  filteredStatus: AppointmentStatus[],
  practitionerIds: number[],
  storeId: number,
) => {
  if (!appointments?.length) return [];
  return (
    appointments
      .filter((item) => {
        if (!filteredStatus.includes(item.appointmentStatus)) return false;

        if (practitionerIds?.length) {
          return practitionerIds.length
            ? getPractitionerIdsInAppointment(item)?.some((id) =>
                practitionerIds.includes(id),
              )
            : item;
        }

        return true;
      })
      ?.map((item) => {
        const resourceIds = getPractitionerIdsInAppointment(item).map((id) =>
          String(id),
        );
        return {
          title: "",
          id: item.id.toString(),
          start: item.dateFrom,
          end: item.dateUntil,
          backgroundColor: getBackgroundColor(item.appointmentStatus),
          extendedProps: {
            practitioners:
              item.appointmentStatus === AppointmentStatus.Absent
                ? item.practitioners
                : [],
            internalNotice: item.internalNote,
            customers: item.customers,
          },
          resourceIds: resourceIds.length ? resourceIds : ["0"],
          borderColor: getBackgroundColor(item.appointmentStatus),
        };
      }) || []
  );
};

export const getEventsByAllocations = (
  allocationsBetweenDates: any,
  appointments: any,
  appointmentStatus: AppointmentStatus[],
) => {
  if (!allocationsBetweenDates?.length) {
    return [];
  }

  return (
    allocationsBetweenDates
      ?.map((item: any) => {
        const appointment = appointments?.find(
          (el) => el.id === item.resourceAllocatorId,
        );
        if (
          appointment &&
          appointmentStatus.includes(appointment.appointmentStatus)
        ) {
          const resourceIds = item.resourceId.toString();
          return createEvent({
            title: "",
            id: appointment.id.toString(),
            start: item.start,
            end: item.end,
            status: appointment.appointmentStatus,
            products: appointment.products,
            practitioners: getPractitionerIdsInAppointment(appointment),
            customers: appointment?.customers || [],
            message: appointment.message,
            internalNotice: appointment.internalNote,
            resourceIds: resourceIds ? [resourceIds] : ["0"],
          });
        }
        return null;
      })
      .filter((item) => item !== null) || []
  );
};
