import React, { useCallback, useState } from "react";
import { GenericForm } from "@3ts/react-ant-crud";
import { useNavigate, useParams } from "react-router-dom";
import { Button, message, Result } from "antd";
import dayjs from "dayjs";
import Loading from "../components/base/Loading";
import PractitionerForm, {
  FormFields,
  parseFormFields,
} from "../components/practitioners/PractitionerForm";
import Content from "../components/layout/Content";
import {
  GetCalendarAppointmentsBetweenDatesDocument,
  GetPractitionersAvailabilitiesByStoreIdDocument,
  GetPractitionersDocument,
  useDeletePractitionerMutation,
  useGetPractitionerQuery,
  useUpdatePractitionerMutation,
} from "../graphql/schema";
import AssociatedAppointmentsModal from "../components/practitioners/AssociatedAppointmentsModal";

const Practitioner = () => {
  const params = useParams();
  const navigate = useNavigate();

  const id = parseInt(params.id!, 10);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  const { data, loading, refetch } = useGetPractitionerQuery({
    variables: {
      id,
    },
  });

  const [update] = useUpdatePractitionerMutation();
  const [remove] = useDeletePractitionerMutation();

  const handleSave = useCallback(
    async (fields: FormFields) => {
      await update({
        variables: {
          id,
          dto: parseFormFields(fields),
        },
        refetchQueries: [
          GetPractitionersDocument,
          GetCalendarAppointmentsBetweenDatesDocument,
          GetPractitionersAvailabilitiesByStoreIdDocument,
        ],
      });
      refetch();

      message.success("Die Änderungen wurden gespeichert.");

      navigate("/practitioners");
    },
    [id],
  );

  const parseAvailability = useCallback(
    (availability: any) => {
      return (
        availability?.map((time: any) => {
          if (!time.start && !time.end) return [];
          const startHours = Number(time.start?.split(":")[0]);
          const startMinutes = Number(time.start?.split(":")[1]);
          const endHours = Number(time.end?.split(":")[0]);
          const endMinutes = Number(time.end?.split(":")[1]);
          return [
            time.start
              ? dayjs(new Date().setHours(startHours, startMinutes))
              : null,
            time.end ? dayjs(new Date().setHours(endHours, endMinutes)) : null,
          ];
        }) || []
      );
    },
    [data],
  );

  const handleDelete = useCallback(async () => {
    remove({
      variables: {
        id,
      },
      refetchQueries: [GetPractitionersDocument],
    });
    navigate("/practitioners");
  }, [id]);

  if (loading) return <Loading />;

  if (!data)
    return (
      <Result
        status="500"
        title="Ups..."
        subTitle="Etwas ist schief gelaufen"
        extra={
          <Button type="primary" onClick={() => navigate(-1)}>
            Zurück
          </Button>
        }
      />
    );

  return (
    <Content>
      <GenericForm
        title={`${data?.practitioner.title} ${data?.practitioner.lastName} bearbeiten`}
        singleItemTitle={`${data?.practitioner.title} ${data?.practitioner.firstName} ${data?.practitioner.lastName}`}
        formRenderer={(formProps) => <PractitionerForm formProps={formProps} />}
        onSave={handleSave}
        onBack={() => {
          navigate("/practitioners");
        }}
        extra={
          <Button danger onClick={() => setDeleteModalVisible(true)}>
            Löschen
          </Button>
        }
        initialValues={{
          active: data?.practitioner.active,
          externalId: data?.practitioner.externalId || "",
          title: data?.practitioner.title || "",
          firstName: data?.practitioner.firstName || "",
          lastName: data?.practitioner.lastName || "",
          categoryId: data?.practitioner.category?.id,
          presenceType: data?.practitioner.presenceType,
          productCategoryIds:
            data?.practitioner.productCategories?.map(
              (category: any) => category.id,
            ) || [],
          storeIds:
            data?.practitioner?.stores?.map((store: any) => store.id) || [],
        }}
      />
      {deleteModalVisible ? (
        <AssociatedAppointmentsModal
          id={id}
          deleteModalVisible={deleteModalVisible}
          setDeleteModalVisible={setDeleteModalVisible}
          handleRemove={handleDelete}
          practitionerId={id}
          startDate={dayjs().toString()}
          endDate={dayjs().add(1, "year").toString()}
        />
      ) : null}
    </Content>
  );
};

export default Practitioner;
