import { Card, Col, Form, Input, Row, Tabs, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { GenericFormProps } from "@3ts/react-ant-crud";
import { QuestionCircleOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import {
  CreatePractitionerInput,
  ResourcePresenceType,
  UpdatePractitionerInput,
  useGetStoresQuery,
} from "../../graphql/schema";
import formItemProps from "../../helper/form/formItemProps";
import StoresSelect from "../store/StoresSelect";
import PractitionerCategorySelect from "./PractitonerCategorySelect";
import AvailabilitiesCalendar from "./AvailabilitiesCalendar";
import AssociatedAppointmentsModal from "./AssociatedAppointmentsModal";
import ProductCategoryTreeSelect from "./ProductCategoryTreeSelect";

export interface FormFields {
  active: boolean;
  title: string;
  firstName: string;
  lastName: string;
  categoryId: number;
  externalId?: string;
  productCategoryIds: number[];
  storeIds: number[];
  availabilitiesConfiguration: any[];
  availabilities: any[];
  presenceType: ResourcePresenceType;
}

export const parseFormFields = (
  fields: FormFields,
): UpdatePractitionerInput & CreatePractitionerInput => {
  return {
    title: fields.title,
    firstName: fields.firstName,
    lastName: fields.lastName,
    categoryId: fields.categoryId,
    productCategoryIds: fields.productCategoryIds,
    storeIds: fields.storeIds,
    externalId: fields.externalId,
  };
};

const colSmall = {
  xs: 24,
  sm: 24,
  md: 24,
  lg: 12,
};

interface Props {
  formProps: GenericFormProps<FormFields>;
  isAvailabilitiesVisible?: boolean;
}

const PractitionerForm = ({
  formProps,
  isAvailabilitiesVisible = true,
}: Props) => {
  const params = useParams();
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [storeId, setStoreId] = useState<number | null>(null);
  const { form } = formProps;
  const { data } = useGetStoresQuery();

  useEffect(() => {
    if (storeId) {
      setDeleteModalVisible(true);
    }
  }, [storeId]);

  if (!data) return null;

  return (
    <Form {...formProps} layout="vertical" form={form}>
      {deleteModalVisible && storeId ? (
        <AssociatedAppointmentsModal
          id={storeId}
          storeId={storeId}
          title="Termine aktualisieren"
          deleteModalVisible={deleteModalVisible}
          setDeleteModalVisible={setDeleteModalVisible}
          handleRemove={() => setStoreId(null)}
          practitionerId={parseInt(params.id, 10)}
          startDate={dayjs().toString()}
          handleCancel={() => {
            form?.setFieldValue("storeIds", [
              ...form.getFieldValue("storeIds"),
              storeId,
            ]);
            setStoreId(null);
          }}
          endDate={dayjs().add(1, "year").toString()}
        />
      ) : null}
      <Tabs>
        <Tabs.TabPane tab="Stammdaten" key="base" forceRender>
          <Card
            title={
              formProps?.initialValues?.presenceType ===
              ResourcePresenceType.Virtual ? (
                <Tooltip title="Die Stammdaten von virtuellen Behandlern können nicht geändert werden.">
                  Stammdaten
                  <QuestionCircleOutlined
                    style={{
                      color: "rgba(0,0,0,.45)",
                      marginLeft: "5px",
                    }}
                  />
                </Tooltip>
              ) : (
                "Stammdaten"
              )
            }
          >
            <Row gutter={24}>
              <Col {...colSmall}>
                <Form.Item {...formItemProps("title", "Titel", true)}>
                  <Input
                    disabled={
                      formProps?.initialValues?.presenceType ===
                      ResourcePresenceType.Virtual
                    }
                  />
                </Form.Item>
              </Col>
              <Col {...colSmall}>
                <Form.Item {...formItemProps("firstName", "Vorname", true)}>
                  <Input
                    disabled={
                      formProps?.initialValues?.presenceType ===
                      ResourcePresenceType.Virtual
                    }
                  />
                </Form.Item>
              </Col>
              <Col {...colSmall}>
                <Form.Item {...formItemProps("lastName", "Nachname", true)}>
                  <Input
                    disabled={
                      formProps?.initialValues?.presenceType ===
                      ResourcePresenceType.Virtual
                    }
                  />
                </Form.Item>
              </Col>
              <Col {...colSmall}>
                <Form.Item
                  tooltip="Die Behandlerkategorie wird von Leistungen verwendet, um Anforderungen an einen Behandler zu definieren."
                  {...formItemProps("categoryId", "Behandlerkategorie", true)}
                >
                  <PractitionerCategorySelect
                    disabled={
                      formProps?.initialValues?.presenceType ===
                      ResourcePresenceType.Virtual
                    }
                  />
                </Form.Item>
              </Col>
              <Col {...colSmall}>
                <Form.Item
                  tooltip="Die Leistungskategorien werden verwendet, um zu definieren, welche Arten von Leistungen ein Behandler durchführen kann."
                  {...formItemProps(
                    "productCategoryIds",
                    "Leistungskategorien",
                    formProps?.initialValues?.presenceType ===
                      ResourcePresenceType.Physical,
                  )}
                >
                  <ProductCategoryTreeSelect
                    disabled={
                      formProps?.initialValues?.presenceType ===
                      ResourcePresenceType.Virtual
                    }
                  />
                </Form.Item>
              </Col>
              <Col {...colSmall}>
                <Form.Item
                  tooltip="Filialen, in denen der Behandler arbeitet."
                  {...formItemProps("storeIds", "Filialen", true)}
                >
                  <StoresSelect
                    disabled={
                      formProps?.initialValues?.presenceType ===
                      ResourcePresenceType.Virtual
                    }
                    onChange={(value) => {
                      if (params.id) {
                        const id = formProps?.initialValues?.storeIds.find(
                          (el: number) => !value.includes(el),
                        );
                        setStoreId(id || null);
                      }
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Externe Konfiguration" key="4" forceRender>
          <Card title="Externe Konfiguration">
            <Form.Item {...formItemProps("externalId", "Externe ID")}>
              <Input />
            </Form.Item>
          </Card>
        </Tabs.TabPane>
        <Tabs.TabPane
          tab="Verfügbarkeiten"
          key="3"
          disabled={!isAvailabilitiesVisible}
        >
          <AvailabilitiesCalendar />
        </Tabs.TabPane>
      </Tabs>
    </Form>
  );
};

export default PractitionerForm;
