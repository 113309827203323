import { FormInstance, message, Modal } from "antd";
import dayjs from "dayjs";
import { addMinutes } from "date-fns";
import { useState } from "react";
import {
  AppointmentProductInput,
  RequiredResourcesFragment,
  ResourceRequirementFragment,
  useValidateVacanciesMutation,
} from "../../graphql/schema";
import { omitDeepArrayWalk } from "../form/removeTypename";
import getProductsTotalDuration from "../common/getProductsTotalDuration";

const { confirm } = Modal;

interface Props {
  form: FormInstance;
}

export interface ValidateVacanciesProps {
  values: any;
  appointmentId?: number;
  index?: number;
  force?: boolean;
  restore?: boolean;
}

export interface ValidateVacancyProps {
  allocationIndex: number;
  productIndex: number;
  id?: number;
}

const useValidateVacancies = ({ form }: Props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [errorData, setErrorData] = useState<any>(null);
  const [allocationTargetIndex, setAllocationTargetIndex] = useState<
    number | undefined
  >(undefined);
  const [productIndex, setProductIndex] = useState<number | undefined>(
    undefined,
  );

  const [validate] = useValidateVacanciesMutation();
  const handleAppointmentDuration = () => {
    const dateFrom = dayjs(form?.getFieldValue("dateFrom"));
    const appointmentProducts = form?.getFieldValue(["products"]);
    const totalDuration = getProductsTotalDuration(appointmentProducts);
    if (dateFrom) {
      form?.setFieldsValue({
        ...form?.getFieldsValue(),
        dateUntil: dayjs(addMinutes(dateFrom.toDate(), totalDuration)),
      });
    }
  };

  const validateVacancies = async ({
    values,
    appointmentId,
    index,
    force,
    restore,
  }: ValidateVacanciesProps) => {
    const date = dayjs(form?.getFieldValue("dateFrom"));
    try {
      if (!date.isValid()) throw new Error("Date is not valid");

      const result = await validate({
        variables: {
          appointmentId,
          date: date.toString(),
          storeId: values.storeId,
          products: omitDeepArrayWalk(
            values.products.map((product: AppointmentProductInput) => ({
              id: product.id,
              productId: product.productId,
              variationId: product?.variationId,
              packageId: product?.packageId,
              requiredResources:
                product?.requiredResources?.map(
                  (resourceConfiguration, i: number) => {
                    const preferredResourceId = product?.allocations?.filter(
                      (a: any) => a.resourceAllocatorId === product.id,
                    )[i]?.resourceId;

                    if (preferredResourceId) {
                      resourceConfiguration.resourceRequirements.push({
                        property: "id",
                        value: [preferredResourceId],
                      });
                    }
                    return {
                      resourceRequirements:
                        resourceConfiguration.resourceRequirements,
                      resourceType: resourceConfiguration.resourceType,
                    };
                  },
                ) || [],
              bookedPrice: String(product.bookedPrice) || "0.00",
            })),
            "__typename",
          ),
          force,
        },
      });

      if (
        result?.data?.validateVacancies &&
        result.data.validateVacancies.length > 0
      ) {
        const targetIndex = index ?? 0;

        form?.setFieldsValue({
          ...values,
          products: result.data.validateVacancies.map(
            (product: any, i: number) => {
              const foundProduct = values.products.find(
                (p: AppointmentProductInput) => p.id === product.id,
              );

              if (!foundProduct) {
                return product;
              }

              if (i >= targetIndex) {
                return product;
              }

              if (restore) {
                // Check if there are any non-package products before the current index
                const hasDependentProducts = values.products
                  .slice(i, values.products.length - 1)
                  .some((p: AppointmentProductInput) => !p.packageId);

                if (!hasDependentProducts) {
                  return foundProduct;
                }
              }

              return product;
            },
          ),
        });
        handleAppointmentDuration();
      }
    } catch (e: any) {
      setIsModalVisible(true);
      setErrorData(e.graphQLErrors?.[0]?.extensions);
      setProductIndex(index);
    }
  };

  const validateVacancy = async ({
    allocationIndex,
    productIndex: index,
    id,
  }: ValidateVacancyProps) => {
    const product = form?.getFieldValue(["products", index]);
    const values = form?.getFieldsValue();
    const date = product.allocations[allocationIndex]?.start
      ? product.allocations[allocationIndex]?.start
      : values.dateFrom.toString();

    try {
      const products = [
        {
          id: product.id,
          productId: product.productId,
          requiredResources: product.requiredResources
            .filter(
              (_: RequiredResourcesFragment, i: number) =>
                i === allocationIndex,
            )
            .map((requiredResource: RequiredResourcesFragment) => {
              // Create a copy of the requiredResource to avoid mutating the original
              const updatedRequiredResource = { ...requiredResource };

              // Find the resource requirement where property === 'id'
              const resourceRequirementForId =
                updatedRequiredResource.resourceRequirements?.find(
                  (resourceRequirement: ResourceRequirementFragment) =>
                    resourceRequirement.property === "id",
                );

              // Get the preferredResourceId from product.allocations
              const preferredResourceId =
                product.allocations[allocationIndex]?.resourceId;

              if (!preferredResourceId) {
                // If there's no preferredResourceId, return the requiredResource as is
                return {
                  resourceRequirements:
                    updatedRequiredResource.resourceRequirements,
                  resourceType: updatedRequiredResource.resourceType,
                };
              }

              if (resourceRequirementForId) {
                // Update the existing resourceRequirementForId
                resourceRequirementForId.value = [preferredResourceId];
              } else {
                // Add a new resourceRequirement with property 'id' and value [preferredResourceId]
                updatedRequiredResource.resourceRequirements = [
                  ...(updatedRequiredResource.resourceRequirements || []),
                  {
                    property: "id",
                    value: [preferredResourceId],
                  },
                ];
              }

              return {
                resourceRequirements:
                  updatedRequiredResource.resourceRequirements,
                resourceType: updatedRequiredResource.resourceType,
              };
            }),
          variationId: product.variationId,
          bookedPrice: String(product.bookedPrice) || "0",
        },
      ];
      await validate({
        variables: {
          appointmentId: id,
          date,
          storeId: values.storeId,
          products: omitDeepArrayWalk(products, "__typename"),
        },
      });
      message.success("Die angefragten Ressource steht zur Verfügung.");
    } catch (e: any) {
      setErrorData(e.graphQLErrors?.[0]?.extensions);
      setIsModalVisible(true);
      setProductIndex(index);
      setAllocationTargetIndex(allocationIndex);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setErrorData(null);
    if (productIndex) {
      const product = form?.getFieldValue(["products", productIndex]);

      form?.setFieldValue(["products", productIndex], {
        ...product,
        allocations: product?.allocations?.map((a: any, j: number) => {
          if (j === allocationTargetIndex) {
            return {
              ...a,
              resourceId: null,
            };
          }
          return a;
        }),
        requiredResources: product.requiredResources.map(
          (r: RequiredResourcesFragment, j: number) => {
            if (j === allocationTargetIndex) {
              return {
                ...r,
                resourceRequirements: r?.resourceRequirements?.filter(
                  (resourceRequirement) =>
                    resourceRequirement.property !== "id",
                ),
              };
            }
            return r;
          },
        ),
      });
    } else {
      const values = form?.getFieldsValue();
      form?.setFieldsValue({
        ...values,
        products: values.products.map(
          (p: AppointmentProductInput, i: number) => {
            if (i === allocationTargetIndex) {
              return {
                ...p,
                productId: null,
                variationId: null,
                bookedPrice: null,
                requiredResources: [],
              };
            }
            return p;
          },
        ),
      });
    }
    setProductIndex(undefined);
    setAllocationTargetIndex(undefined);
  };

  const handleOk = async () => {
    try {
      setIsModalVisible(false);
      setErrorData(null);
      if (productIndex !== undefined && allocationTargetIndex === undefined) {
        await validateVacancies({
          values: form?.getFieldsValue(),
          appointmentId: undefined,
          index: productIndex,
          force: true,
          restore: true,
        });
      }
    } catch (e: any) {
      message.error("Fehler beim Wiederherstellen der Ressourcen");
    }
    setAllocationTargetIndex(undefined);
    setProductIndex(undefined);
  };

  return {
    validateVacancies,
    validateVacancy,
    isModalVisible,
    errorData,
    allocationTargetIndex,
    handleCancel,
    handleOk,
  };
};

export default useValidateVacancies;
