import React from "react";
import { Tree } from "antd";
import { where } from "@3ts/react-ant-crud";
import {
  ProductCategoryFragment,
  useGetProductCategoriesQuery,
} from "../../graphql/schema";

interface Props {
  value?: [number];
  onChange?: (value: number[]) => void;
  disabled?: boolean;
  filter?: any;
}

const ProductCategoryTreeSelect = ({
  value,
  onChange,
  filter = {},
  disabled,
}: Props) => {
  const { data } = useGetProductCategoriesQuery({
    fetchPolicy: "no-cache",
    variables: {
      options: {
        ...where<ProductCategoryFragment>(filter),
        itemsPerPage: 10000,
      },
    },
  });

  return (
    <Tree
      checkedKeys={value?.map((v) => `0-${v}`)}
      checkable
      onCheck={(keys: any, val) => {
        if (onChange)
          onChange(keys?.map((node: any) => Number(node.split("-")[1])) || []);
      }}
      treeData={data?.productCategories.items
        ?.filter((item) => !item.parentId)
        ?.map((item) => ({
          title: item.name,
          key: `${item.parentId ? 1 : 0}-${item.id}`,
          children: item.children?.map((child) => ({
            title: child.name,
            key: `${item.parentId ? 1 : 0}-${child.id}`,
          })),
        }))}
    />
  );
};

export default ProductCategoryTreeSelect;
