import React, { useState } from "react";
import { Button, Card, Form, message, Space, Table, Typography } from "antd";
import { where } from "@3ts/react-ant-crud";
import { CheckOutlined, CloseOutlined, PlusOutlined } from "@ant-design/icons";
import {
  GetProductCategoriesDocument,
  ProductCategoryFragment,
  useCreateProductCategoryMutation,
  useDeleteProductCategoryMutation,
  useGetProductCategoriesQuery,
  useUpdateProductCategoryMutation,
} from "../graphql/schema";
import Content from "../components/layout/Content";
import ProductCategoryDrawer from "../components/products/ProductCategoryDrawer";
import handleGqlError from "../helper/common/handleGqlError";

interface FormFields {
  name: string;
  coupon: [];
  practitionerIds: [];
  serviceIds: [];
  parentId: number | null;
}

interface ExpandedDataType {
  key: React.Key;
  name: string;
  upgradeNum: string;
}

const ProductCategories = () => {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [drawerId, setDrawerId] = useState<number | null>(null);
  const [form] = Form.useForm();
  const [create] = useCreateProductCategoryMutation();
  const [update] = useUpdateProductCategoryMutation();
  const [remove] = useDeleteProductCategoryMutation();

  const { data: productCategories, refetch } = useGetProductCategoriesQuery({
    fetchPolicy: "no-cache",
    variables: {
      options: {
        ...where<ProductCategoryFragment>({
          parentId: null,
        }),
      },
    },
  });

  const handleCreate = async (values: Partial<FormFields>) => {
    try {
      await create({
        variables: {
          dto: {
            name: values.name || "",
            parentId: values.parentId || null,
          },
        },
        refetchQueries: [GetProductCategoriesDocument],
      });
      refetch();
      message.success("Kategorie wurde erstellt.");
    } catch (e: any) {
      handleGqlError(e, {
        PARENT_ENTITY_NOT_FOUND: (error: any) => {
          message.error("Elternkategorie nicht gefunden.");
        },
        default: (error: any) => {
          message.error("Kategorie konnte nicht erstellt werden.");
        },
      });
    }
  };

  const handleUpdate = async (id: number, values: Partial<FormFields>) => {
    try {
      await update({
        variables: {
          id,
          dto: {
            name: values.name || "",
            parentId: values.parentId || null,
          },
        },
        refetchQueries: [GetProductCategoriesDocument],
      });
      refetch();
      message.success("Kategorie wurde erstellt.");
    } catch (e: any) {
      handleGqlError(e, {
        PARENT_ENTITY_NOT_FOUND: (error: any) => {
          message.error("Elternkategorie nicht gefunden.");
        },
        CHILD_CANNOT_BE_PARENT: (error: any) => {
          message.error("Kind kann nicht Elternkategorie sein.");
        },
        default: (error: any) => {
          message.error("Kategorie konnte nicht erstellt werden.");
        },
      });
    }
  };

  const handleDelete = async (id: number) => {
    try {
      await remove({
        variables: {
          id,
        },
      });
      message.success("Kategorie wurde gelöscht.");
    } catch (e: any) {
      handleGqlError(e, {
        CONFLICT: (error: any) => {
          message.error(
            "Kategorie kann nicht gelöscht werden, da der Kategorie Leistungen zugewiesen sind.",
          );
        },
        CANNOT_DELETE_PARENT_WITH_CHILDREN: (error: any) => {
          message.error(
            "Kategorie kann nicht gelöscht werden, da die Kategorie Unterkategorien hat.",
          );
        },
        default: (error: any) => {
          message.error("Kategorie konnte nicht gelöscht werden.");
        },
      });
    }
  };

  return (
    <Content>
      <Typography.Title
        level={3}
        style={{
          marginBottom: 30,
        }}
      >
        Leistungskategorien
      </Typography.Title>
      <Card>
        <Table
          key="product-categories"
          dataSource={
            productCategories?.productCategories?.items
              ?.filter((item) => !item.parentId)
              ?.map((item) => ({
                ...item,
                key: item.id,
              })) || []
          }
          onRow={(record) => {
            return {
              style: { cursor: "pointer" },
              onClick: () => {
                setDrawerId(record.id);
                setDrawerVisible(true);
                form?.setFieldsValue({
                  id: record.id,
                  name: record.name,
                  parentId: record.parentId,
                  children: record.children,
                });
              },
            };
          }}
          columns={[
            {
              title: "Name",
              dataIndex: "name",
              key: "name",
            },
            {
              title: "Hauptkategorie",
              dataIndex: "parentId",
              key: "parentId",
              render: (value: any[]) =>
                !value ? <CheckOutlined /> : <CloseOutlined />,
            },
            {
              title: "Unterkategorien",
              dataIndex: "children",
              key: "children",
              render: (value: any[], record: ProductCategoryFragment) =>
                !record?.parentId ? value?.length || 0 : null,
            },
          ]}
        />
      </Card>
      <ProductCategoryDrawer
        form={form}
        id={drawerId}
        open={drawerVisible}
        onClose={() => {
          setDrawerVisible(false);
          setDrawerId(null);
        }}
        onUpdate={handleUpdate}
        onDelete={handleDelete}
        onCreate={handleCreate}
      />
      {!drawerVisible && (
        <div
          style={{
            position: "fixed",
            bottom: 30,
            right: 30,
            zIndex: 1000,
          }}
        >
          <Button
            type="primary"
            shape="round"
            size="large"
            onClick={() => {
              setDrawerId(null);
              setDrawerVisible(true);
            }}
          >
            <Space>
              <PlusOutlined />
              <span>Leistungskategorie hinzufügen</span>
            </Space>
          </Button>
        </div>
      )}
    </Content>
  );
};

export default ProductCategories;
