import React from "react";
import { DataTable } from "@3ts/react-ant-crud";
import { useNavigate } from "react-router-dom";
import { WarningOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import {
  GetPractitionerListQuery,
  GetPractitionerListQueryVariables,
  ListPractitionerFragment,
  useGetPractitionerListLazyQuery,
} from "../graphql/schema";
import Content from "../components/layout/Content";

const Practitioners = () => {
  const navigate = useNavigate();

  return (
    <Content>
      <DataTable<
        ListPractitionerFragment,
        GetPractitionerListQuery,
        GetPractitionerListQueryVariables
      >
        id="practitioners"
        title="Behandler"
        singleItemTitle="Behandler"
        query={useGetPractitionerListLazyQuery}
        onClickCreate={() => {
          navigate("/createPractitioner");
        }}
        onClickRow={(item) => {
          navigate(`/practitioner/${item.id}`);
        }}
        columns={{
          title: {
            label: "Titel",
          },
          firstName: {
            label: "Vorname",
          },
          lastName: {
            label: "Nachname",
          },
          externalId: {
            label: "Externe ID",
            render: (externalId) =>
              externalId || (
                <Tooltip title="Diese Behandler hat keine externe ID. Um Fehler zu vermeiden, trage die zugehörige externe ID ein.">
                  <WarningOutlined
                    style={{
                      color: "red",
                    }}
                  />
                </Tooltip>
              ),
          },
          category: {
            label: "Kategorie",
            render: (category) => {
              return category?.name;
            },
          },
          productCategories: {
            label: "Leistungskategorien",
            render: (productCategories) => {
              return productCategories
                ?.map((productCategory) => productCategory.name)
                .join(", ");
            },
          },
        }}
      />
    </Content>
  );
};

export default Practitioners;
