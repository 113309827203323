import { AppointmentProductInput } from "../../graphql/schema";

interface Props {
  products: AppointmentProductInput[];
  currentId: number;
}

const getDependentProducts = ({ products, currentId }: Props) => {
  const targetIndex = products.findIndex((product) => product.id === currentId);
  return products.filter((product, i) => i > targetIndex && !product.packageId);
};

export default getDependentProducts;
