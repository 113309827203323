import {
  Badge,
  Button,
  Col,
  List,
  notification,
  Popover,
  Row,
  Tabs,
  Typography,
} from "antd";
import { InboxOutlined } from "@ant-design/icons";
import React, { useCallback, useEffect, useState } from "react";
import { format } from "date-fns";
import {
  AppointmentStatus,
  BookingChannel,
  CalendarInboxAppointmentFragment,
  useGetAppointmentsForCalendarInboxQuery,
} from "../../../../../graphql/schema";
import StatusName from "../../drawer/StatusName";
import { useCalendar } from "../CalendarContext";
import dayjs from "dayjs";

interface Props {
  openDrawer: boolean;
  refetchAppointments: () => void;
  onOpenAppointment: (appointmentId: number, start: Date) => void;
}

interface InboxProps {
  appointments: any;
  onOpenAppointment: (appointmentId: number, start: Date) => void;
}

const InboxList = ({ appointments, onOpenAppointment }: InboxProps) => {
  if (appointments?.length === 0)
    return <Typography.Text>Keine Termine</Typography.Text>;

  const sortedAppointments = [...(appointments ?? [])].sort(
    (a: any, b: any) =>
      new Date(b.dateOfBooking).getTime() - new Date(a.dateOfBooking).getTime(),
  );

  return (
    <List
      style={{ maxHeight: 300, overflow: "auto" }}
      dataSource={sortedAppointments}
      renderItem={(item: any) => (
        <Row
          onClick={() => onOpenAppointment(item.id, new Date(item.dateFrom))}
          justify="center"
          style={{
            borderBottom: "1px solid #f0f0f0",
            marginBottom: 10,
            cursor: "pointer",
            backgroundColor: "#FAF7F0",
            borderRadius: 5,
          }}
        >
          <Col span={22}>
            <Row>
              <Col span={24}>
                <StatusName status={item.appointmentStatus} />
              </Col>
            </Row>
            <Row>
              <Col>
                <Typography.Text>
                  {format(new Date(item.dateFrom), "dd.MM.yyyy")}
                </Typography.Text>
              </Col>
            </Row>
            {item?.customers?.map(
              (
                customer: { firstName: string; lastName: string },
                i: number,
              ) => (
                <Row key={i}>
                  <Col>
                    {customer?.firstName} {customer?.lastName}
                  </Col>
                </Row>
              ),
            )}
          </Col>
        </Row>
      )}
    />
  );
};

interface ContentProps {
  appointmentsNew: any;
  appointmentsConfirmed: any;
  onOpenAppointment: (appointmentId: number, start: Date) => void;
}

const InboxContent = ({
  appointmentsNew,
  appointmentsConfirmed,
  onOpenAppointment,
}: ContentProps) => {
  return (
    <Tabs>
      <Tabs.TabPane tab="Neu" key="new" forceRender>
        <InboxList
          appointments={appointmentsNew}
          onOpenAppointment={onOpenAppointment}
        />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Bestätigt ohne Behandler" key="blocker" forceRender>
        <InboxList
          appointments={appointmentsConfirmed}
          onOpenAppointment={onOpenAppointment}
        />
      </Tabs.TabPane>
    </Tabs>
  );
};

const AppointmentsInbox = ({
  openDrawer,
  onOpenAppointment,
  refetchAppointments,
}: Props) => {
  const { storeId, eventInfo } = useCalendar();
  const [visible, setVisible] = useState(false);
  const [date, setDate] = useState(new Date());
  const [api, contextHolder] = notification.useNotification();
  const {
    data: appointments,
    refetch: refetchAppointmentInbox,
    previousData,
  } = useGetAppointmentsForCalendarInboxQuery({
    fetchPolicy: "no-cache",
    variables: {
      newStatus: AppointmentStatus.New,
      confirmedStatus: AppointmentStatus.Confirmed,
      storeId: storeId ?? Number.MIN_SAFE_INTEGER,
      filteredId: parseInt(eventInfo?.event?.id, 10) ?? Number.MIN_SAFE_INTEGER,
      dateFrom: date,
    },
    pollInterval: 60 * 1000,
    skip: !storeId,
  });
  const handleVisibleChange = (value: boolean) => {
    setVisible(value);
  };

  const openNotification = useCallback(
    (appointmentDate: Date) => {
      api.info({
        message: `Ein neuer Termin wurde am ${dayjs(appointmentDate)
          .tz()
          .format("DD.MM.YYYY HH:mm")} eingebucht`,
        placement: "topRight",
      });
    },
    [api, appointments],
  );

  const findNewAppointments = useCallback(() => {
    const previousAppointments = [
      ...(previousData?.newAppointments || []),
      ...(previousData?.confirmedAppointments || []),
    ];
    return [
      ...(appointments?.newAppointments || []),
      ...(appointments?.confirmedAppointments || []),
    ]
      ?.filter((appointment: CalendarInboxAppointmentFragment) => {
        return !previousAppointments.find(
          (prevAppointment: any) => prevAppointment.id === appointment.id,
        );
      })
      ?.filter((appointment: CalendarInboxAppointmentFragment) => {
        return BookingChannel.BookingTrack === appointment.bookingChannel;
      });
  }, [appointments, previousData]);

  useEffect(() => {
    const newAppointments = findNewAppointments();
    if (newAppointments.length && previousData) {
      newAppointments.forEach(
        (appointment: CalendarInboxAppointmentFragment) => {
          openNotification(appointment.dateFrom);
        },
      );
      refetchAppointments();
    }
  }, [previousData, appointments]);

  const handleOnOpenAppointment = (appointmentId: number, start: Date) => {
    onOpenAppointment(appointmentId, start);
    setVisible(false);
  };

  return (
    <>
      {contextHolder}
      <Popover
        placement="bottom"
        title="Offene Termine"
        trigger="hover"
        open={visible}
        onOpenChange={handleVisibleChange}
        content={
          <InboxContent
            onOpenAppointment={handleOnOpenAppointment}
            appointmentsNew={appointments?.newAppointments || []}
            appointmentsConfirmed={appointments?.confirmedAppointments || []}
          />
        }
      >
        <Badge
          count={
            (appointments?.newAppointments?.length || 0) +
            (appointments?.confirmedAppointments?.length || 0)
          }
          style={{
            top: 10,
            right: 10,
          }}
        >
          <Button
            style={{
              margin: 10,
              marginRight: 5,
              marginLeft: 5,
            }}
            icon={<InboxOutlined />}
          />
        </Badge>
      </Popover>
    </>
  );
};

export default AppointmentsInbox;
