import { Form, Select } from "antd";
import { useMemo } from "react";
import { where } from "@3ts/react-ant-crud";
import {
  ResourcePresenceType,
  useGetPractitionersAvailabilitiesByStoreIdQuery,
  useGetPractitionersQuery,
} from "../../../../graphql/schema";

interface Props {
  value?: number | null;
  onChange?: (value: number) => void;
  categories?: number[];
  multiple?: boolean;
  productCategoryId?: number;
}

const PractitionersWithAvailabilitiesSelect = ({
  value,
  multiple = true,
  onChange,
  categories,
  productCategoryId,
}: Props) => {
  const form = Form.useFormInstance();

  const startDate = form.getFieldValue("dateFrom");
  const endDate = form.getFieldValue("dateFrom");
  const storeId = form.getFieldValue("storeId");
  const { data } = useGetPractitionersAvailabilitiesByStoreIdQuery({
    variables: {
      storeId,
      start: startDate?.toString(),
      end: endDate?.toString(),
    },
    fetchPolicy: "no-cache",
    skip: !startDate || !endDate || !storeId,
  });

  const { data: practitioners } = useGetPractitionersQuery({
    skip: !storeId,
    fetchPolicy: "no-cache",
    variables: {
      options: {
        ...where({
          "stores.id": storeId,
        }),
        itemsPerPage: 1000,
      },
    },
  });

  const status = useMemo(() => {
    if (
      value &&
      data?.practitionersAvailabilitiesByStoreId &&
      data?.practitionersAvailabilitiesByStoreId.length > 0
    ) {
      const practitionerAvailability =
        data?.practitionersAvailabilitiesByStoreId.find(
          (room) => room?.practitioner?.id === value,
        );
      if (!practitionerAvailability) return "error";
    }

    if (
      data?.practitionersAvailabilitiesByStoreId &&
      data.practitionersAvailabilitiesByStoreId.length === 0
    ) {
      return "warning";
    }
    return "";
  }, [value, data]);

  const options = useMemo(() => {
    const practitionersAvailabilities =
      data?.practitionersAvailabilitiesByStoreId?.filter((availabilities) => {
        if (!categories) return true;
        const practitionerCategoryIds =
          availabilities.practitioner?.category?.id;
        if (productCategoryId && practitionerCategoryIds) {
          return (
            (categories.includes(practitionerCategoryIds) &&
              availabilities?.practitioner?.presenceType ===
                ResourcePresenceType.Virtual) ||
            (categories.includes(practitionerCategoryIds) &&
              availabilities.practitioner?.productCategories?.some(
                (category) => category.id === productCategoryId,
              ))
          );
        }
        return availabilities.practitioner?.category?.id
          ? categories.includes(availabilities.practitioner?.category?.id)
          : false;
      });

    if (
      practitionersAvailabilities &&
      practitionersAvailabilities?.length > 0
    ) {
      return practitionersAvailabilities.map((availabilities) => {
        return {
          value: availabilities.practitioner?.id,
          label:
            availabilities.practitioner?.presenceType ===
            ResourcePresenceType.Virtual
              ? `${availabilities.practitioner?.title}`
              : `${availabilities.practitioner?.title} ${availabilities.practitioner?.firstName} ${availabilities.practitioner?.lastName}`,
        };
      });
    }

    const practitionersList = practitioners?.practitioners?.items?.filter(
      (practitioner) => {
        if (!categories) return true;
        return practitioner.category?.id
          ? categories.includes(practitioner?.category?.id)
          : false;
      },
    );

    if (practitionersList && practitionersList.length > 0) {
      return practitionersList.map((practitioner) => {
        return {
          value: practitioner?.id,
          label:
            practitioner?.presenceType === ResourcePresenceType.Virtual
              ? `${practitioner?.title}`
              : `${practitioner?.title} ${practitioner?.firstName} ${practitioner?.lastName}`,
        };
      });
    }

    return [];
  }, [data, practitioners]);

  return (
    <Select
      status={status}
      value={value}
      onChange={(val) => {
        if (onChange) onChange(val);
      }}
      options={options}
      mode={multiple ? "multiple" : undefined}
      allowClear
    />
  );
};

export default PractitionersWithAvailabilitiesSelect;
