import { Select } from "antd";
import { FilterExpression, where } from "@3ts/react-ant-crud";
import {
  ProductCategoryFragment,
  useGetProductCategoriesQuery,
} from "../../graphql/schema";

interface Props {
  value?: number | null;
  onChange?: (value: number) => void;
  disabled?: boolean;
  filter?: FilterExpression<ProductCategoryFragment>;
}

const ProductCategorySelect = ({
  value,
  onChange,
  disabled,
  filter,
}: Props) => {
  const { data } = useGetProductCategoriesQuery({
    fetchPolicy: "no-cache",
    variables: {
      options: {
        ...where(filter || {}),
      },
    },
  });

  return (
    <Select
      disabled={disabled}
      value={value}
      onChange={(val) => {
        if (onChange) onChange(val);
      }}
      showSearch
      filterOption={(input: string, option: any) =>
        option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      options={
        data?.productCategories.items.map((productCategory) => {
          return {
            value: productCategory.id,
            key: `${productCategory.name} ${productCategory.id}`,
            label: productCategory.name,
          };
        }) || []
      }
      allowClear
    />
  );
};

export default ProductCategorySelect;
