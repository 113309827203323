import { Card, Checkbox, Col, Form, Input, Row, Space, Tabs } from "antd";
import React from "react";
import { DataTable, GenericFormProps, where } from "@3ts/react-ant-crud";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import {
  CommunicationType,
  Country,
  CreateCustomerInput,
  CustomerType,
  GetTreatmentsQuery,
  GetTreatmentsQueryVariables,
  TreatmentFragment,
  UpdateCustomerInput,
  useGetTreatmentsLazyQuery,
} from "../../graphql/schema";
import formItemProps from "../../helper/form/formItemProps";
import CustomerTypeSelect from "./CustomerTypeSelect";
import CommunicationTypeSelect from "./CommunicationTypeSelect";
import CountrySelect from "./CountrySelect";
import CustomerCalendar from "./CustomerCalendar";
import FileUpload from "./FileUpload";
import SelectCountryCode from "./SelectCountryCode";

const nameRegex = /^[a-zA-ZäöüßÄÖÜẞ\- ]{2,50}$/g;
const phoneRegex = /^\+?\d{7,12}$/;
const mailRegex =
  /^[A-Za-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[A-Za-z0-9-]+\.)+[A-Za-z]{2,}$/;

export interface FormFields {
  firstName: string;
  lastName: string;
  email: string;
  preferredCommunicationType: CommunicationType;
  cooperation: boolean;
  category: CustomerType;
  termsAccepted: boolean;
  phone?: string;
  alternativePhone?: string;
  streetNumber: string;
  city: string;
  zipCode: string;
  country: Country;
  street: string;
  note: string;
  countryCodeAlternativePhone: string;
  countryCodePhone: string;
}

export const parseFormFields = (
  fields: FormFields,
): UpdateCustomerInput & CreateCustomerInput => {
  return {
    firstName: fields.firstName,
    lastName: fields.lastName,
    email: fields.email,
    preferredCommunicationType: fields.preferredCommunicationType,
    cooperation: fields.cooperation,
    category: fields.category,
    termsAccepted: true,
    note: fields.note,
    phone: `${fields.countryCodePhone} ${fields.phone}`,
    alternativePhone: `${fields.countryCodeAlternativePhone} ${fields.alternativePhone}`,
    streetNumber: fields.streetNumber || "",
    city: fields.city || "",
    zipCode: fields.zipCode || "",
    country: fields.country,
    street: fields.street || "",
  };
};

const colSmall = {
  xs: 24,
  sm: 24,
  md: 24,
  lg: 12,
};

interface Props {
  id?: number | null;
  formProps: GenericFormProps<FormFields>;
}

const CustomerForm = ({ formProps, id }: Props) => {
  const navigate = useNavigate();
  return (
    <Form {...formProps} layout="vertical">
      <Tabs>
        <Tabs.TabPane tab="Stammdaten" key="base">
          <Space direction="vertical" style={{ width: "100%" }}>
            <Card title="Stammdaten">
              <Row gutter={24}>
                <Col {...colSmall}>
                  <Form.Item
                    {...formItemProps("firstName", "Vorname", true, [
                      {
                        required: true,
                        validator: (rule, value) => {
                          return value.length < 50
                            ? Promise.resolve()
                            : Promise.reject();
                        },
                        message: "Die maximale Länge beträgt 50 Zeichen.",
                      },
                      {
                        required: true,
                        validator: (rule, value) => {
                          return new RegExp(nameRegex).test(value)
                            ? Promise.resolve()
                            : Promise.reject();
                        },
                        message: "Bitte trage deinen Vornamen ein.",
                      },
                    ])}
                  >
                    <Input minLength={2} maxLength={50} showCount />
                  </Form.Item>
                </Col>
                <Col {...colSmall}>
                  <Form.Item {...formItemProps("note", "Notiz", false)}>
                    <Input.TextArea />
                  </Form.Item>
                </Col>
                <Col {...colSmall}>
                  <Form.Item
                    {...formItemProps("lastName", "Nachname", true, [
                      {
                        required: true,
                        validator: (rule, value) => {
                          return value.length < 50
                            ? Promise.resolve()
                            : Promise.reject();
                        },
                        message: "Die maximale Länge beträgt 50 Zeichen.",
                      },
                      {
                        required: true,
                        validator: (rule, value) => {
                          return new RegExp(nameRegex).test(value)
                            ? Promise.resolve()
                            : Promise.reject();
                        },
                        message: "Bitte trage deinen Nachnamen ein.",
                      },
                    ])}
                  >
                    <Input minLength={2} maxLength={50} showCount />
                  </Form.Item>
                </Col>
                <Col {...colSmall}>
                  <Form.Item
                    {...formItemProps("email", "E-Mail", true, [
                      {
                        required: true,
                        type: "email",
                        validator: (rule, value) => {
                          return new RegExp(mailRegex).test(value)
                            ? Promise.resolve()
                            : Promise.reject();
                        },
                        message: "Bitte trage deine E-Mail ein.",
                      },
                    ])}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col {...colSmall}>
                  <Form.Item
                    {...formItemProps("phone", "Telefon", false, [
                      {
                        message: "Bitte trage die Mobilnummer des Kunden ein.",
                        validator: (rule, value) => {
                          if (!value || value.length === 0)
                            return Promise.resolve();

                          return new RegExp(phoneRegex).test(value)
                            ? Promise.resolve()
                            : Promise.reject();
                        },
                      },
                    ])}
                  >
                    <Input
                      addonBefore={
                        <Form.Item
                          name="countryCodePhone"
                          noStyle
                          initialValue="+49"
                        >
                          <SelectCountryCode />
                        </Form.Item>
                      }
                      style={{
                        borderRadius: "10px",
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col {...colSmall}>
                  <Form.Item
                    {...formItemProps(
                      "alternativePhone",
                      "Telefon alternativ",
                      false,
                      [
                        {
                          required: false,
                          message: "Bitte trage deine Mobilnummer ein.",
                          validator: (rule, value) => {
                            if (!value) return Promise.resolve();
                            return new RegExp(phoneRegex).test(value)
                              ? Promise.resolve()
                              : Promise.reject();
                          },
                        },
                      ],
                    )}
                  >
                    <Input
                      addonBefore={
                        <Form.Item
                          name="countryCodeAlternativePhone"
                          noStyle
                          required={false}
                          initialValue="+49"
                        >
                          <SelectCountryCode />
                        </Form.Item>
                      }
                      style={{
                        borderRadius: "10px",
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col {...colSmall}>
                  <Form.Item {...formItemProps("street", "Straße", false)}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col {...colSmall}>
                  <Form.Item
                    {...formItemProps("streetNumber", "Hausnummer", false)}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col {...colSmall}>
                  <Form.Item
                    {...formItemProps("zipCode", "Postleitzahl", false)}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col {...colSmall}>
                  <Form.Item {...formItemProps("city", "Stadt", false)}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col {...colSmall}>
                  <Form.Item {...formItemProps("country", "Land", true)}>
                    <CountrySelect />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
            <Card title="Kundennotizen">
              <Row gutter={24}>
                <Col {...colSmall}>
                  <Form.Item
                    tooltip="Aktuell werden nur E-Mails unterstützt."
                    {...formItemProps(
                      "preferredCommunicationType",
                      "bevorzugter Kommunikationskanal",
                      false,
                    )}
                  >
                    <CommunicationTypeSelect disabled />
                  </Form.Item>
                </Col>
                <Col {...colSmall}>
                  <Form.Item
                    {...formItemProps(
                      "cooperation",
                      "Kooperationspartner",
                      false,
                    )}
                    valuePropName="checked"
                  >
                    <Checkbox>Ist Kooperationspartner</Checkbox>
                  </Form.Item>
                </Col>
                <Col {...colSmall}>
                  <Form.Item
                    {...formItemProps("category", "Kundenkategorie", true)}
                  >
                    <CustomerTypeSelect />
                  </Form.Item>
                </Col>
                <Col {...colSmall}>
                  <Form.Item
                    {...formItemProps("termsAccepted", "AGB's", true)}
                    valuePropName="checked"
                  >
                    <Checkbox>AGBs gelesen und akzeptiert</Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Space>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Termine" key="2">
          <CustomerCalendar id={id} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Behandlungen" key="3">
          {id ? (
            <DataTable<
              TreatmentFragment,
              GetTreatmentsQuery,
              GetTreatmentsQueryVariables
            >
              id="treatment"
              queryVariables={{
                options: {
                  ...where<TreatmentFragment>({
                    ["customers.id" as string]: { _in: [id] },
                  }),
                },
              }}
              query={useGetTreatmentsLazyQuery}
              singleItemTitle="Behandlung"
              columns={{
                date: {
                  label: "Datum",
                  render: (_, record) =>
                    dayjs(record.dateFrom).tz().format("DD.MM.YYYY"),
                },
                dateFrom: {
                  label: "Beginn",
                  render: (date) => dayjs(date).tz().format("HH:mm"),
                },
                dateUntil: {
                  label: "Ende",
                  render: (date) => dayjs(date).tz().format("HH:mm"),
                },
                paymentStatus: {
                  label: "Zahlungsstatus",
                  render: (status) => {
                    if (status === "NOT_PAID") return "Nicht bezahlt";
                    if (status === "PAID") return "Bezahlt";
                    if (status === "PARTIALLY_PAID") return "Teilweise bezahlt";

                    return "Unbekannt";
                  },
                },
                paymentDate: {
                  label: "Zahlungsdatum",
                  render: (date) =>
                    date ? dayjs(date).tz().format("DD.MM.YYYY") : "",
                },
              }}
              onClickRow={(item) => {
                navigate(`/treatment/${item.id}`);
              }}
            />
          ) : null}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Dokumente" key="documents" disabled={!id}>
          <Row>
            <Col xs={24}>
              <FileUpload />
            </Col>
          </Row>
        </Tabs.TabPane>
      </Tabs>
    </Form>
  );
};

export default CustomerForm;
