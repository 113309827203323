import React from "react";
import { DataTable, where } from "@3ts/react-ant-crud";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/pro-light-svg-icons";
import { Tooltip } from "antd";
import { WarningOutlined } from "@ant-design/icons";
import {
  GetProductListQuery,
  GetProductListQueryVariables,
  ListProductFragment,
  ProductFragment,
  useGetProductListLazyQuery,
} from "../graphql/schema";
import Content from "../components/layout/Content";

const Products = () => {
  const navigate = useNavigate();

  return (
    <Content>
      <DataTable<
        ListProductFragment,
        GetProductListQuery,
        GetProductListQueryVariables
      >
        id="product"
        title="Leistungen"
        singleItemTitle="Leistung"
        queryVariables={{
          options: {
            ...where<ProductFragment>({
              nextVersionId: null,
            }),
          },
        }}
        query={useGetProductListLazyQuery}
        columns={{
          id: "ID",
          name: "Name",
          index: "Index",
          isPackage: {
            label: "Paket",
            render: (v, record) =>
              record?.packageProducts?.length ? (
                <FontAwesomeIcon icon={faCheck} />
              ) : (
                <FontAwesomeIcon icon={faTimes} />
              ),
          },
          available: {
            label: "Buchbar",
            render: (v, record) => {
              if (v && !record?.externalId) {
                return (
                  <Tooltip title="Diese Leistung ist buchbar, hat allerdings keine externe ID. Um Fehler zu vermeiden, trage die zugehörige externe ID ein.">
                    <WarningOutlined
                      style={{
                        color: "red",
                      }}
                    />
                  </Tooltip>
                );
              }
              return v ? (
                <FontAwesomeIcon icon={faCheck} />
              ) : (
                <FontAwesomeIcon icon={faTimes} />
              );
            },
          },
          blockedBetweenTreatments: "Schonzeit in Tagen",
          isSubProduct: {
            label: "Unterleistung",
            render: (v) =>
              v ? (
                <FontAwesomeIcon icon={faCheck} />
              ) : (
                <FontAwesomeIcon icon={faTimes} />
              ),
          },
        }}
        onClickCreate={() => {
          navigate("/createProduct");
        }}
        onClickRow={(item) => {
          navigate(`/product/${item.id}`);
        }}
      />
    </Content>
  );
};

export default Products;
