import React, { useMemo } from "react";
import { TreeSelect } from "antd";
import { FilterExpression, where } from "@3ts/react-ant-crud";
import {
  ProductCategoryFragment,
  useGetProductCategoriesQuery,
} from "../../graphql/schema";

interface Props {
  value?: number | number[] | string | undefined;
  onChange?: (value: number) => void;
  disabled?: boolean;
  filter?: FilterExpression<ProductCategoryFragment>;
}

const ProductCategoryTreeSelect = ({
  value,
  onChange,
  filter = {},
  disabled,
}: Props) => {
  const { data, loading, error } = useGetProductCategoriesQuery({
    fetchPolicy: "no-cache",
    variables: {
      options: {
        ...where<ProductCategoryFragment>(filter),
        itemsPerPage: 10000,
      },
    },
  });

  const formatValue = (
    v: number | string | number[] | undefined,
    categories: ProductCategoryFragment[],
  ) => {
    if (!value) return undefined;
    if (!Array.isArray(v) && v && v.toString().includes("-")) return v;

    const val = Array.isArray(v) ? v[0] : v;
    const category = categories?.find(
      (item: ProductCategoryFragment) => item.id === val,
    );

    return category ? `${category.parentId ? 1 : 0}-${category.id}` : undefined;
  };

  const generateTreeData = (categories: ProductCategoryFragment[]) => {
    return (
      categories
        ?.filter((item) => !item.parentId)
        ?.map((item) => ({
          title: item.name,
          value: `0-${item.id}`,
          children: item.children?.map((child) => ({
            title: child.name,
            value: `1-${child.id}`,
          })),
        })) || []
    );
  };

  const valueId = useMemo(
    () => formatValue(value, data?.productCategories?.items || [])?.toString(),
    [value, data],
  );

  // Event handler for onChange
  const handleChange = (val: string) => {
    if (onChange) {
      const selectedId = Number(val.split("-")[1]);
      onChange(selectedId);
    }
  };

  return (
    <TreeSelect
      loading={loading}
      showSearch
      status={error ? "error" : undefined}
      treeNodeFilterProp="title"
      style={{ width: "100%" }}
      value={valueId}
      onChange={handleChange}
      treeData={generateTreeData(data?.productCategories?.items || [])}
      disabled={disabled}
    />
  );
};

export default ProductCategoryTreeSelect;
