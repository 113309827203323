import {
  AppointmentProductInput,
  useGetProductLazyQuery,
} from "../../graphql/schema";

const useGetBaseProduct = () => {
  const [fetchProduct] = useGetProductLazyQuery();

  const getBaseProduct = async (
    appointmentProduct: AppointmentProductInput,
  ) => {
    const product = await fetchProduct({
      variables: {
        id: appointmentProduct.productId.toString(),
      },
    });
    const variation = product?.data?.product?.variations?.find(
      (item) => item.id === appointmentProduct?.variationId,
    );
    return variation || product?.data?.product;
  };
  return {
    getBaseProduct,
  };
};

export default useGetBaseProduct;
