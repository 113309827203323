import {
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Space,
  Tooltip,
} from "antd";
import React from "react";
import { GenericFormProps } from "@3ts/react-ant-crud";
import { Dayjs } from "dayjs";
import { QuestionCircleOutlined } from "@ant-design/icons";
import {
  CouponDiscountType,
  CouponUsageType,
  CreateCouponInput,
  CustomerType,
  UpdateCouponInput,
} from "../../graphql/schema";
import formItemProps from "../../helper/form/formItemProps";
import CouponUsageTypeSelect from "./CouponUsageTypeSelect";
import CouponDiscountTypeSelect from "./CouponTypeSelect";
import CustomerTypeSelect from "../customer/CustomerTypeSelect";
import ProductsCheckboxGroup from "../products/ProductsCheckboxGroup";
import ProductCategoriesCheckboxGroup from "../products/ProductCategoriesCheckboxGroup";
import StoreCheckboxGroup from "../store/StoreCheckboxGroup";
import VariationCheckboxGroup from "../products/VariationCheckboxGroup";
import StaticTimezoneDatePicker from "../staticTimezoneDatePicker/StaticTimezoneDatePicker";

export interface FormFields {
  name: string;
  code: string;
  description: string;
  expirationDate: Dayjs | undefined;
  startDate: Dayjs | undefined;
  customerType: CustomerType;
  usageType: CouponUsageType;
  realCoupon: boolean;
  variationIds: number[];
  combinable: boolean;
  storeIds: number[];
  productCategoryIds: number[];
  productIds: number[];
  couponType: CouponDiscountType;
  value: string;
}

export const parseFormFields = (
  fields: FormFields,
): UpdateCouponInput & CreateCouponInput => {
  return {
    name: fields.name,
    code: fields.code,
    description: fields.description,
    expirationDate: fields.expirationDate,
    startDate: fields.startDate,
    customerType: fields.customerType,
    usageType: fields.usageType,
    realCoupon: fields.realCoupon,
    combinable: fields.combinable,
    storeIds: fields.storeIds,
    variationIds: fields.variationIds || [],
    productCategoryIds: fields.productCategoryIds || [],
    productIds: fields.productIds || [],
    couponType: fields.couponType,
    value: fields.value.toString(),
  };
};

const colSmall = {
  xs: 24,
  sm: 24,
  md: 24,
  lg: 12,
};

interface Props {
  formProps: GenericFormProps<FormFields>;
  editCode?: boolean;
}

const CouponForm = ({ formProps, editCode = false }: Props) => {
  const { form } = formProps;

  const handleChange = (values: any) => {
    form?.setFieldsValue({
      ...values,
      couponType: values.realCoupon
        ? CouponDiscountType.Amount
        : CouponDiscountType.Percentage,
    });
  };

  return (
    <Form
      {...formProps}
      layout="vertical"
      form={form}
      onValuesChange={(changedValues, values) => handleChange(values)}
    >
      <Space size={24} direction="vertical">
        <Card title="Allgemein">
          <Row gutter={24}>
            <Col {...colSmall}>
              <Form.Item {...formItemProps("name", "Name", true)}>
                <Input />
              </Form.Item>
            </Col>
            <Col {...colSmall}>
              <Form.Item
                tooltip="Der Gutscheincode kann nicht geändert werden."
                {...formItemProps("code", "Code", true)}
              >
                <Input disabled={!editCode} />
              </Form.Item>
            </Col>
            <Col {...colSmall}>
              <Form.Item
                {...formItemProps("description", "Beschreibung", true)}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col {...colSmall} />
            <Col {...colSmall}>
              <Form.Item {...formItemProps("startDate", "Gültig ab", true)}>
                <StaticTimezoneDatePicker
                  style={{
                    width: "100%",
                  }}
                  format="DD.MM.YYYY"
                />
              </Form.Item>
            </Col>
            <Col {...colSmall}>
              <Form.Item
                {...formItemProps("expirationDate", "Gültig bis", true)}
              >
                <StaticTimezoneDatePicker
                  style={{
                    width: "100%",
                  }}
                  format="DD.MM.YYYY"
                />
              </Form.Item>
            </Col>
          </Row>
        </Card>
        <Card title="Details">
          <Row gutter={24}>
            <Col {...colSmall}>
              <Form.Item
                tooltip="Der Kundentyp bestimmt, wer den Gutschein einlösen kann."
                {...formItemProps("customerType", "Kundentyp", true)}
              >
                <CustomerTypeSelect />
              </Form.Item>
            </Col>
            <Col {...colSmall}>
              <Form.Item
                tooltip="Einfach: Der Gutschein wir nur auf die erste berechtigte Leistung im Warenkorb angewendet. Mehrfach: Der Gutschein wir auf alle berechtigten Leistungen im Warenkorb angewendet."
                {...formItemProps("usageType", "Einfach / Mehrfach", true)}
              >
                <CouponUsageTypeSelect />
              </Form.Item>
            </Col>
            <Col {...colSmall}>
              <Form.Item
                tooltip="Wertgutscheine sind Gutscheine, für die Rückstellungen gebildet werden müssen."
                {...formItemProps("realCoupon", "Wertgutschein", false)}
                valuePropName="checked"
              >
                <Checkbox />
              </Form.Item>
            </Col>
            <Col {...colSmall}>
              <Form.Item
                tooltip="Kombinierbarkeit bestimmt, ob der Gutschein mit anderen Gutscheinen kombiniert werden kann."
                {...formItemProps("combinable", "Kombinierbar", false)}
                valuePropName="checked"
              >
                <Checkbox />
              </Form.Item>
            </Col>
            <Col {...colSmall}>
              <Form.Item
                tooltip="Der Gutscheintyp bestimmt, ob der Gutschein einen festen Betrag oder einen Prozentsatz des Preises der jeweiligen Leistung abzieht."
                {...formItemProps("couponType", "Gutscheintyp", true)}
              >
                <CouponDiscountTypeSelect />
              </Form.Item>
            </Col>
            <Col {...colSmall}>
              <Form.Item
                tooltip="Der Wert des Gutscheins. Bei Prozenten (z.B. 10 für 10%)."
                {...formItemProps("value", "Betrag", true)}
              >
                <InputNumber precision={2} style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>
        </Card>
        <Card
          title={
            <Tooltip
              title="Der Anwendungsbereich bestimmt, auf welche
            Filialen, Leistungskategorien, Leistungen und Variationen der Gutschein
            angewendet werden kann."
            >
              Anwendungsbereich
              <QuestionCircleOutlined
                style={{
                  marginLeft: 8,
                  color: "rgba(0, 0, 0, 0.45)",
                }}
              />
            </Tooltip>
          }
        >
          <Row gutter={24}>
            <Col {...colSmall}>
              <Form.Item
                tooltip="Filialen, in denen der Gutschein eingelöst werden kann. Bei keiner Auswahl ist der Gutschein in keiner Filiale gültig."
                {...formItemProps("storeIds", "Filiale", true)}
              >
                <StoreCheckboxGroup />
              </Form.Item>
            </Col>
            <Col {...colSmall}>
              <Form.Item
                tooltip="Leistungskategorien, auf die der Gutschein angewendet werden kann. Bei keiner Auswahl ist der Gutschein auf keine Leistungskategorie anwendbar."
                {...formItemProps(
                  "productCategoryIds",
                  "Leistungskategorie",
                  false,
                )}
              >
                <ProductCategoriesCheckboxGroup
                  filter={{
                    parentId: null,
                  }}
                  onChange={(productCategoryIds, productIds, variationIds) => {
                    form?.setFieldsValue({
                      productIds,
                      productCategoryIds,
                      variationIds,
                    });
                  }}
                />
              </Form.Item>
            </Col>
            <Col {...colSmall}>
              <Form.Item
                tooltip="Leistungen, auf die der Gutschein angewendet werden kann. Bei keiner Auswahl ist der Gutschein auf keine Leistung anwendbar."
                {...formItemProps("productIds", "Leistungen", false)}
              >
                <ProductsCheckboxGroup />
              </Form.Item>
            </Col>
            <Col {...colSmall}>
              <Form.Item
                tooltip="Variationen, auf die der Gutschein angewendet werden kann. Bei keiner Auswahl ist der Gutschein auf keine Variation anwendbar."
                {...formItemProps("variationIds", "Variationen", false)}
              >
                <VariationCheckboxGroup />
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Space>
    </Form>
  );
};

export default CouponForm;
