import React from "react";
import { Col, Row, Typography } from "antd";

interface Props<T> {
  label?: string | undefined;
  data: T[];
  attributes: Array<keyof T>;
  writeOutFirst?: boolean;
  icon?: React.ReactNode;
  style?: React.CSSProperties;
}

function PopOverList<Fragment extends { id?: string | number }>({
  label,
  data,
  attributes,
  icon,
  style,
  writeOutFirst,
}: Props<Fragment>) {
  if (!data?.length) return null;

  return (
    <Row>
      <Col span={24}>
        {label && (
          <Row>
            <Col>
              <Typography.Text strong>{label}</Typography.Text>
            </Col>
          </Row>
        )}
        {writeOutFirst && data.length > 1 ? (
          <Row key={data[0]?.id || 0}>
            <Col span={24}>
              <div style={{ display: "flex", alignItems: "center" }}>
                {icon}
                <Typography.Text
                  style={{
                    whiteSpace: "normal",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    ...style,
                  }}
                >
                  {`${attributes
                    .filter((attribute) => attribute !== "id")
                    .map((attribute) => String(data[0][attribute] || ""))
                    .join(" ")} und ${data.length - 1} weitere`}
                </Typography.Text>
              </div>
            </Col>
          </Row>
        ) : (
          data.map((item, index) => (
            <Row key={item.id || index}>
              <Col span={24}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {icon}
                  <Typography.Text
                    style={{
                      whiteSpace: "normal",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      ...style,
                    }}
                  >
                    {attributes
                      .filter((attribute) => attribute !== "id")
                      .map((attribute) => String(item[attribute] || ""))
                      .join(" ")}
                  </Typography.Text>
                </div>
              </Col>
            </Row>
          ))
        )}
      </Col>
    </Row>
  );
}

export default PopOverList;
