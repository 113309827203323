import { Col, Row, Tooltip, Typography } from "antd";
import React from "react";
import { QuestionCircleOutlined } from "@ant-design/icons";
import {
  AppointmentStatus,
  CalendarStoreFragment,
} from "../../../../graphql/schema";
import CustomerSearch from "./toolbar/CustomerSearch";

const colors = [
  {
    status: AppointmentStatus.New,
    color: "#3399ff",
    text: "Terminanfrage",
  },
  {
    status: AppointmentStatus.Confirmed,
    color: "#FED049",
    text: "Bestätigter Termin",
  },
  {
    status: AppointmentStatus.ConfirmedWithVirtualPractitioner,
    color: "#F39C12",
    text: "Bestätigter Termin mit virtuellem Behandler",
  },
  {
    status: AppointmentStatus.CustomerArrived,
    color: "#00cc00",
    text: "Kunde ist eingetroffen",
  },
  {
    status: AppointmentStatus.Accomplished,
    color: "#00DFA2",
    text: "Termin wurde durchgeführt",
  },
  {
    status: AppointmentStatus.Cancelled,
    color: "#ff4040",
    text: "Termin wurde durch die Filiale abgesagt",
  },
  {
    status: AppointmentStatus.CancelledByCustomer,
    color: "#ff4040",
    text: "Termin wurde durch den Kunden abgesagt",
  },
  {
    status: AppointmentStatus.Absent,
    color: "#343a40",
    text: "Der Behandler ist abwesend",
  },
  {
    status: AppointmentStatus.RescheduledByKalialab,
    color: "#696969",
    text: "Termin wurde durch Kalialab verschoben",
  },
  {
    status: AppointmentStatus.NoShow,
    color: "#adb5bd",
    text: "Kunde ist nicht erschienen",
  },
];

interface Props {
  store: Partial<CalendarStoreFragment>;
}

const CalendarTitle = ({ store }: Props) => {
  return (
    <Row justify="space-between">
      <Col>
        <Tooltip
          overlayStyle={{ minWidth: 300, width: 400, maxWidth: 400 }}
          color="#f0f2f5"
          title={
            <Row>
              {colors.map((color) => (
                <Col key={color.status} span={24}>
                  <Typography.Text>
                    <span
                      style={{
                        display: "inline-block",
                        width: 10,
                        height: 10,
                        backgroundColor: color.color,
                        borderRadius: "50%",
                        marginRight: 5,
                      }}
                    />
                    {color.text}
                  </Typography.Text>
                </Col>
              ))}
            </Row>
          }
        >
          <Typography.Title level={5}>
            Legende
            <QuestionCircleOutlined
              style={{
                marginLeft: 5,
                color: "rgba(0,0,0,.45)",
              }}
            />
          </Typography.Title>
        </Tooltip>
        <CustomerSearch />
      </Col>
      <Col
        style={{
          textAlign: "end",
        }}
      >
        <Typography.Title level={3}>
          {store?.name || "Filiale auswählen"}
        </Typography.Title>
      </Col>
    </Row>
  );
};

export default CalendarTitle;
