import { Flex, Popover } from "antd";
import React, { useMemo } from "react";
import PopoverContent from "./PopoverContent";
import {
  CustomerType,
  PractitionerFragment,
} from "../../../../../graphql/schema";
import PopOverList from "./PopOverList";
import PopOverPremium from "./PopOverPremium";
import PopOverMessage from "./PopOverMessage";

interface Props {
  info: any;
}

const EventPopover = ({ info }: Props) => {
  const { event } = info;

  const isPremium = useMemo(
    () =>
      event.extendedProps.customers.some(
        (customer: any) => customer.category === CustomerType.Premium,
      ),
    [event],
  );
  return (
    <Popover
      style={{
        width: "100%",
        height: "100%",
        position: "relative",
        overflow: "hidden",
        padding: 2,
        whiteSpace: "nowrap",
        maxHeight: "100%",
      }}
      content={<PopoverContent id={event?.id} />}
      trigger="hover"
      placement="right"
    >
      <Flex
        vertical
        style={{
          overflow: "hidden",
          whiteSpace: "nowrap",
          maxHeight: "100%",
        }}
      >
        <PopOverList
          data={event?.extendedProps?.customers || []}
          attributes={["id", "firstName", "lastName"]}
          style={{
            color: "white",
            overflow: "hidden",
            fontSize: "12px",
          }}
        />
        <PopOverList<PractitionerFragment>
          data={event?.extendedProps?.practitioners || []}
          attributes={["firstName", "lastName"]}
          writeOutFirst
          style={{
            color: "white",
            overflow: "hidden",
            fontSize: "12px",
          }}
        />

        {isPremium && <PopOverPremium />}
        <PopOverMessage
          text={event.extendedProps.internalNotice}
          theme="light"
        />
      </Flex>
    </Popover>
  );
};

export default EventPopover;
