import React from "react";
import { Spin } from "antd";

interface Props {
  style?: React.CSSProperties;
}

const Loading = ({ style }: Props) => {
  return (
    <div style={{ textAlign: "center", ...style }}>
      <Spin spinning />
    </div>
  );
};
export default Loading;
