import React from "react";
import { DataTable } from "@3ts/react-ant-crud";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import {
  GetCouponListQuery,
  GetCouponListQueryVariables,
  ListCouponFragment,
  useGetCouponListLazyQuery,
} from "../graphql/schema";
import Content from "../components/layout/Content";

const Coupons = () => {
  const navigate = useNavigate();
  return (
    <Content>
      <DataTable<
        ListCouponFragment,
        GetCouponListQuery,
        GetCouponListQueryVariables
      >
        id="coupon"
        title="Gutscheine"
        singleItemTitle="Gutschein"
        query={useGetCouponListLazyQuery}
        columns={{
          name: "Name",
          code: "Code",
          description: "Beschreibung",
          expirationDate: {
            label: "Ablaufdatum",
            render: (value) => {
              return value ? dayjs(value).format("DD.MM.YYYY") : undefined;
            },
          },
          startDate: {
            label: "Startdatum",
            render: (value) => {
              return value ? dayjs(value).format("DD.MM.YYYY") : undefined;
            },
          },
        }}
        onClickCreate={() => {
          navigate("/createCoupon");
        }}
        onClickRow={(item) => {
          navigate(`/coupon/${item.id}`);
        }}
      />
    </Content>
  );
};

export default Coupons;
