import { Col, Row, Skeleton, Typography } from "antd";
import { UserOutlined } from "@ant-design/icons";
import React, { useMemo } from "react";
import dayjs from "dayjs";
import StatusName from "../../drawer/StatusName";
import {
  CalendarCustomerFragment,
  PopOverAppointmentProductFragment,
  useGetCalendarPopoverAppointmentQuery,
} from "../../../../../graphql/schema";
import PopOverList from "./PopOverList";
import PopOverMessage from "./PopOverMessage";

const PopoverContent = ({ id }: { id: number }) => {
  const { data, loading, error } = useGetCalendarPopoverAppointmentQuery({
    variables: {
      id: id.toString() ?? Number.MIN_SAFE_INTEGER.toString(),
    },
    skip: !id,
  });

  const getNonPackageProducts = useMemo(() => {
    return (
      data?.appointment?.products?.filter(
        (product: any) => !product?.package?.id,
      ) ?? []
    );
  }, [data]);

  if (loading) return <Skeleton active />;
  if (error || !data) return null;

  const {
    appointmentStatus,
    dateFrom,
    dateUntil,
    message,
    customers,
    internalNote,
  } = data.appointment;

  return (
    <Row
      style={{
        padding: 5,
        width: 200,
        overflow: "hidden",
      }}
    >
      <Col span={24}>
        <Row>
          <Col span={24}>
            <Typography.Text
              style={{
                fontSize: "12px",
                fontWeight: "lighter",
              }}
            >
              <StatusName status={appointmentStatus} /> (
              {dayjs(dateFrom).tz().format("HH:mm")} -{" "}
              {dayjs(dateUntil).tz().format("HH:mm")})
            </Typography.Text>
          </Col>
        </Row>
        <PopOverList<CalendarCustomerFragment>
          data={customers ?? []}
          attributes={["firstName", "lastName"]}
          icon={
            <UserOutlined
              style={{
                padding: 10,
                fontSize: "20px",
              }}
            />
          }
        />
        <PopOverList<PopOverAppointmentProductFragment>
          label="Leistungen"
          data={getNonPackageProducts}
          attributes={["name"]}
        />

        <PopOverMessage text={message} label="Nachricht" theme="dark" />
        <PopOverMessage
          text={internalNote ?? ""}
          label="Interne Notiz"
          theme="dark"
        />
      </Col>
    </Row>
  );
};

export default PopoverContent;
