import { Flex, Modal, Select, Tooltip } from "antd";
import { CrownFilled, SearchOutlined } from "@ant-design/icons";
import { CustomerType, useGetCustomersSelectQuery } from "../../graphql/schema";
import { useCalendar } from "../treatment/appointment/calendar/CalendarContext";

const { confirm } = Modal;

interface Props {
  value?: [number] | null;
  onChange?: (value: number[]) => void;
  disabled?: boolean;
}

const CustomersSelect = ({ value, onChange, disabled = false }: Props) => {
  const { setOpenCustomerDrawer } = useCalendar();
  const { data } = useGetCustomersSelectQuery({
    fetchPolicy: "no-cache",
    variables: {
      options: {
        itemsPerPage: 10000,
      },
    },
  });

  return (
    <Select
      value={value}
      onChange={(val) => {
        const customer = data?.customers?.items?.find((c) => c.id === val);
        if (customer && customer.note) {
          confirm({
            title: `Kundennotiz:`,
            content: customer?.note,
            okText: "OK",
          });
        }
        if (onChange) onChange(val);
      }}
      showSearch
      disabled={disabled}
      filterOption={(input: string, option: any) =>
        option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      options={data?.customers?.items
        ?.filter((customer) => customer.email)
        ?.map((customer) => {
          return {
            value: customer.id,
            key: `${customer.firstName} ${customer.lastName} ${customer.id}`,
            label: (
              <Flex justify="space-between">
                <Flex>
                  {customer.category === CustomerType.Premium && (
                    <Tooltip title="Premiumkunde">
                      <span
                        style={{
                          color: "#fadb14",
                        }}
                      >
                        <CrownFilled />
                      </span>
                    </Tooltip>
                  )}{" "}
                  {`${customer.firstName} ${customer.lastName}`}
                </Flex>
                <SearchOutlined
                  onClick={(event) => {
                    event.stopPropagation();
                    setOpenCustomerDrawer(customer.id);
                  }}
                  style={{
                    cursor: "pointer",
                  }}
                />
              </Flex>
            ),
          };
        })}
    />
  );
};

export default CustomersSelect;
