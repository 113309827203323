import { Button } from "antd";
import React from "react";
import { addDays } from "date-fns";
import { useCalendar } from "../CalendarContext";

interface Props {
  calenderRef: any;
}

const ViewOptions = ({ calenderRef }: Props) => {
  const { setInitialView, setInitialDate, setDates } = useCalendar();
  return (
    <>
      <Button
        style={{
          margin: 10,
          marginRight: 0,
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          marginLeft: 5,
        }}
        onClick={() => {
          if (calenderRef.current) {
            calenderRef.current.getApi().changeView("resourceTimeGridDay");
            const date = calenderRef.current.getApi().view.currentStart;
            setInitialView("resourceTimeGridDay");
            setInitialDate(date);
            setDates({
              start: date,
              end: addDays(date, 1),
            });
          }
        }}
      >
        Tag
      </Button>
      <Button
        style={{
          margin: 10,
          marginRight: 0,
          borderRadius: 0,
          marginLeft: 0,
        }}
        onClick={() => {
          if (calenderRef.current) {
            calenderRef.current.getApi().changeView("timeGridThreeDays");
            const date = calenderRef.current.getApi().view.currentStart;
            setInitialView("timeGridThreeDays");
            setInitialDate(date);
            setDates({
              start: date,
              end: addDays(date, 3),
            });
          }
        }}
      >
        3 Tage
      </Button>
      <Button
        onClick={() => {
          if (calenderRef.current) {
            calenderRef.current.getApi().changeView("timeGridWeek");
            const date = calenderRef.current.getApi().view.currentStart;
            setInitialView("timeGridWeek");
            setInitialDate(date);
            setDates({
              start: date,
              end: addDays(date, 7),
            });
          }
        }}
        style={{
          margin: 10,
          marginRight: 5,
          marginLeft: 0,
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
        }}
      >
        Woche
      </Button>
    </>
  );
};
export default ViewOptions;
