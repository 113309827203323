import React from "react";
import { CrownFilled } from "@ant-design/icons";

interface Props {
  style?: React.CSSProperties;
}

const PopOverPremium = ({ style }: Props) => {
  return (
    <CrownFilled
      style={{
        fontSize: "13px",
        position: "absolute",
        bottom: 5,
        left: 5,
        ...style,
      }}
    />
  );
};

export default PopOverPremium;
