import { Select } from "antd";
import { where } from "@3ts/react-ant-crud";
import { useGetProductsSelectQuery } from "../../graphql/schema";

interface Props {
  value?: [number] | null;
  onChange?: (value: number[]) => void;
  amount?: number;
  disabled?: boolean;
  filter?: any;
}

const ProductsSelect = ({
  amount,
  value,
  onChange,
  filter = {},
  disabled,
}: Props) => {
  const { data } = useGetProductsSelectQuery({
    fetchPolicy: "no-cache",
    variables: {
      options: {
        ...where(filter),
        itemsPerPage: 10000,
      },
    },
  });
  return (
    <Select
      value={value}
      onChange={(val) => {
        if (onChange) onChange(val);
      }}
      disabled={disabled}
      options={
        data?.products.items.map((product) => {
          return {
            value: product.id,
            label: product.name,
          };
        }) || []
      }
      mode="multiple"
      allowClear
    />
  );
};

export default ProductsSelect;
