import { Button, Col, Typography } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import dayjs, { Dayjs } from "dayjs";
import React from "react";
import StaticTimezoneDatePicker from "../../../../staticTimezoneDatePicker/StaticTimezoneDatePicker";
import { useCalendar } from "../CalendarContext";

interface Props {
  drawerVisible: boolean;
  calendarRef: any;
}

const DatePickerOptions = ({ drawerVisible, calendarRef }: Props) => {
  const { viewDates, setDates } = useCalendar();

  const getViewTypeDateRangeSize = () => {
    switch (calendarRef.current.getApi().view.type) {
      case "timeGridThreeDays":
        return 3;
      case "timeGridWeek":
        return 7;
      case "resourceTimeGridDay":
        return 1;
      default:
        return 0;
    }
  };

  const getViewTypeUnit = () => {
    switch (calendarRef.current.getApi().view.type) {
      case "timeGridThreeDays":
        return "day";
      case "timeGridWeek":
        return "week";
      case "resourceTimeGridDay":
        return "day";
      default:
        return "day";
    }
  };

  const skipTime = (direction: "REVERSE" | "FORWARD") => {
    if (calendarRef.current) {
      const skip = (amount: number) => {
        const modifier = direction === "FORWARD" ? "add" : "subtract";
        const start = dayjs(viewDates.start)[modifier](amount, "days");
        const end = dayjs(viewDates.end)[modifier](amount, "days");

        setDates({
          start: start.toDate(),
          end: end.toDate(),
        });
        calendarRef.current.getApi().gotoDate(start.toDate());
      };
      skip(getViewTypeDateRangeSize());
    }
  };

  const startWithCurrentDay = () => {
    const start = dayjs().tz().startOf(getViewTypeUnit());

    if (calendarRef.current) {
      setDates({
        start: start.toDate(),
        end: start.add(getViewTypeDateRangeSize(), "days").toDate(),
      });
      calendarRef.current.getApi().gotoDate(start.toDate());
    }
  };

  const formatThreeDayRange = (value: Dayjs) => {
    return value
      ? `${value.tz().format("DD.MM.")} - ${value
          .add(2, "days")
          .tz()
          .format("DD.MM")}`
      : "";
  };

  const handleThreeDayRangeChange = (date: Dayjs) => {
    if (date) {
      setDates({
        start: date.toDate(),
        end: date.add(2, "days").toDate(),
      });
      if (calendarRef.current) {
        calendarRef.current.getApi().gotoDate(date?.toDate());
      }
    }
  };

  const handleSingleDayChange = (_: any, dateString: string | string[]) => {
    const [day, month, year] = (dateString as string)
      .split(".")
      .map((v) => parseInt(v, 10));

    let start = dayjs()
      .date(day)
      .month(month - 1)
      .year(year)
      .startOf("day")
      .tz();

    if (!start.isValid()) start = dayjs().tz();

    setDates({
      start: start.toDate(),
      end: start.add(24, "hours").toDate(),
    });
    if (calendarRef.current) {
      calendarRef.current.getApi().gotoDate(start.toDate());
    }
  };

  const formatWeekRange = (value: Dayjs) => (value ? `KW ${value.week()}` : "");
  const handleWeekRangeChange = (date: Dayjs) => {
    if (date) {
      setDates({
        start: date.toDate(),
        end: date.add(6, "days").toDate(),
      });
      if (calendarRef.current) {
        calendarRef.current.getApi().gotoDate(date.toDate());
      }
    }
  };

  return (
    <Col span={drawerVisible ? 12 : 8}>
      <Button
        style={{
          margin: 10,
          marginRight: 10,
          marginLeft: 0,
        }}
        onClick={() => {
          startWithCurrentDay();
        }}
      >
        <Typography.Text>Heute</Typography.Text>
      </Button>
      <Button
        style={{
          margin: 10,
          marginRight: 5,
          marginLeft: 5,
        }}
        onClick={() => {
          skipTime("REVERSE");
        }}
        icon={<LeftOutlined />}
      />
      {calendarRef?.current?.getApi()?.view?.type === "timeGridThreeDays" && (
        <StaticTimezoneDatePicker
          picker="date"
          defaultValue={dayjs(viewDates.start).tz()}
          format={formatThreeDayRange}
          onChange={handleThreeDayRangeChange}
          allowClear={false}
          value={dayjs(viewDates.start).tz()}
          style={{
            margin: 10,
            marginLeft: 0,
            marginRight: 5,
          }}
        />
      )}
      {calendarRef?.current?.getApi()?.view?.type === "resourceTimeGridDay" && (
        <StaticTimezoneDatePicker
          picker="date"
          format="DD.MM.YYYY"
          value={dayjs(viewDates.start).tz()}
          defaultValue={dayjs(viewDates.start).tz()}
          onChange={handleSingleDayChange}
          allowClear={false}
          style={{
            margin: 10,
            marginLeft: 0,
            marginRight: 5,
          }}
        />
      )}
      {calendarRef?.current?.getApi()?.view?.type === "timeGridWeek" && (
        <StaticTimezoneDatePicker.WeekPicker
          format={formatWeekRange}
          defaultValue={dayjs(viewDates.start).tz()}
          onChange={handleWeekRangeChange}
          value={dayjs(viewDates.start).tz()}
          style={{
            margin: 10,
            marginLeft: 0,
            marginRight: 5,
          }}
        />
      )}
      <Button
        style={{
          margin: 10,
          marginLeft: 0,
        }}
        onClick={() => {
          skipTime("FORWARD");
        }}
        icon={<RightOutlined />}
      />
    </Col>
  );
};

export default DatePickerOptions;
