import React from "react";
import { Col, Row, Typography } from "antd";
import {
  getThemeColor,
  ThemeColor,
} from "../../../../../helper/common/getThemeColor";

interface Props {
  label?: string | undefined;
  text: string;
  theme?: ThemeColor;
}

const PopOverMessage = ({ label, theme = "light", text }: Props) => {
  if (!text) return null;
  return (
    <Row>
      {label && (
        <Col span={24}>
          <Typography.Text
            strong
            style={{
              color: getThemeColor(theme),
            }}
          >
            {label}
          </Typography.Text>
        </Col>
      )}
      <Col span={24}>
        <Typography.Text
          style={{
            color: getThemeColor(theme),
          }}
        >
          {text}
        </Typography.Text>
      </Col>
    </Row>
  );
};

export default PopOverMessage;
