import { Select } from "antd";
import { useGetStoresSelectQuery } from "../../graphql/schema";

interface Props {
  value?: [number] | null;
  onChange?: (value: number[]) => void;
  disabled?: boolean;
}

const StoresSelect = ({ value, onChange, disabled }: Props) => {
  const { data } = useGetStoresSelectQuery({
    fetchPolicy: "no-cache",
    variables: {
      options: {
        itemsPerPage: 1000,
      },
    },
  });

  return (
    <Select
      value={value}
      onChange={(val) => {
        if (onChange) onChange(val);
      }}
      disabled={disabled}
      showSearch
      filterOption={(input, option) => {
        if (!option || !option.label || !input) return false;
        return option?.label?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0;
      }}
      options={
        data?.stores.items.map((store) => {
          return {
            value: store.id,
            label: store.name,
          };
        }) || []
      }
      mode="multiple"
      allowClear
    />
  );
};

export default StoresSelect;
