import { Button, Col, Form, InputNumber, Row } from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import React, { useCallback } from "react";
import dayjs from "dayjs";
import AllocationsConfigList from "./AllocationsConfigList";
import {
  AppointmentProductInput,
  Customer,
  useGetCustomerQuery,
} from "../../../graphql/schema";
import formItemProps from "../../../helper/form/formItemProps";
import VariationSelect from "../../products/VariationSelect";
import getProductsTotalDuration from "../../../helper/common/getProductsTotalDuration";
import getPriceForCustomer from "../../../helper/common/getPriceForCustomer";
import AppointmentProductSelect from "./AppointmentProductSelect";
import { useAppointment } from "../appointment/context/AppointmentContext";
import { useCalendar } from "../appointment/calendar/CalendarContext";
import useGetBaseProduct from "../../../helper/hooks/useGetBaseProduct";
import getDependentProducts from "../../../helper/common/getDependentProducts";

interface Props {
  id: number;
}

const colSmall = {
  xs: 24,
  sm: 24,
  md: 24,
  lg: 24,
};

const AppointmentProductsList = ({ id }: Props) => {
  const { storeId } = useCalendar();
  const form = Form.useFormInstance();
  const formProducts = Form.useWatch("products", form);
  const { validateVacancies } = useAppointment();
  const { getBaseProduct } = useGetBaseProduct();

  const { data: customer } = useGetCustomerQuery({
    variables: {
      id: form?.getFieldValue("customerIds")?.[0],
    },
    skip: !form?.getFieldValue("customerIds")?.[0],
  });

  const add = useCallback(() => {
    const list = form?.getFieldValue("products") || [];

    const newList = [
      ...list,
      {
        id: undefined,
        productId: undefined,
        variationId: undefined,
        usedCouponIds: [],
        requiredResources: [],
        totalDuration: 0,
        allocations: [],
        package: undefined,
      },
    ];
    form?.setFieldValue("products", newList);
  }, [form]);

  const handleAppointmentDuration = useCallback(() => {
    const dateFrom = dayjs(form?.getFieldValue("dateFrom"));
    const appointmentProducts = form?.getFieldValue(["products"]);
    const totalDuration = getProductsTotalDuration(appointmentProducts);

    if (dateFrom) {
      form?.setFieldValue(
        "dateUntil",
        dayjs(dateFrom.toDate()).add(totalDuration, "minute"),
      );
    }
  }, [form]);

  const handleTotalPrice = useCallback(() => {
    const appointmentProducts = form?.getFieldValue(["products"]);
    const totalPrice = appointmentProducts.reduce(
      (acc: any, product: AppointmentProductInput) => {
        return acc + Number(product.bookedPrice);
      },
      0,
    );
    form?.setFieldsValue({
      ...form?.getFieldsValue(),
      bookedPrice: String(totalPrice),
    });
  }, [form]);

  const removeAssociatedPackageProducts = useCallback(
    ({
      appointmentProducts,
      targetId,
      filterTarget,
    }: {
      appointmentProducts: AppointmentProductInput[];
      targetId: number;
      filterTarget: boolean;
    }) => {
      if (!targetId) return;

      const productIdsToRemove = new Set<number>();

      // Build a map from product IDs to their immediate children
      const childrenMap = appointmentProducts.reduce(
        (acc, product) => {
          if (product.packageId) {
            if (!acc[product.packageId]) {
              acc[product.packageId] = [];
            }
            acc[product.packageId].push(product);
          }
          return acc;
        },
        {} as { [key: number]: AppointmentProductInput[] },
      );

      // Recursive function to collect all descendants
      const collectDescendants = (currentId: number) => {
        const children = childrenMap[currentId] || [];
        children.forEach((child) => {
          if (child.id) {
            productIdsToRemove.add(child.id);
            collectDescendants(child.id); // Recursively collect child's descendants
          }
        });
      };

      // Start collecting from the targetId
      const dependentProducts = getDependentProducts({
        products: appointmentProducts,
        currentId: targetId,
      });

      // Start collecting from the targetId
      collectDescendants(targetId);

      dependentProducts.forEach((product) => {
        if (product.id) {
          collectDescendants(product.id); // Recursively collect child's descendants
        }
      });
      const dependentIds = dependentProducts.map((product) => product.id);

      const updatedProducts = form
        ?.getFieldValue("products")
        ?.filter((product: AppointmentProductInput) =>
          product.id ? !productIdsToRemove.has(product.id) : true,
        )
        ?.filter((product: AppointmentProductInput) =>
          filterTarget ? product.id !== targetId : true,
        )
        ?.map((product: AppointmentProductInput) => ({
          ...product,
          id: dependentIds.includes(product.id) ? undefined : product.id,
        }));

      form?.setFieldsValue({
        ...form?.getFieldsValue(),
        products: updatedProducts,
      });
      productIdsToRemove.add(targetId); // Add the target product itself (if not filtered out above)

      dependentProducts.forEach((product) => {
        if (product.id) {
          productIdsToRemove.add(product.id);
        }
      });
    },
    [form, formProducts],
  );

  const appointmentExpired = useCallback(() => {
    const start = dayjs(form?.getFieldValue("dateFrom"));
    return start ? start.isBefore(new Date()) : false;
  }, [form]);

  const handleChangeProduct = useCallback(
    async (value: any, name: number, target: string) => {
      // Update the specific field value in the form
      form?.setFieldValue([name, target], value);

      // Retrieve the current list of products from the form
      const productsList = form?.getFieldValue("products") || [];
      const currentProduct = productsList[name];

      // Remove associated package products
      removeAssociatedPackageProducts({
        appointmentProducts: productsList,
        targetId: currentProduct.id,
        filterTarget: false,
      });

      // Get the base product once
      const baseProduct = await getBaseProduct(currentProduct);

      // Calculate the booked price
      const bookedPrice = getPriceForCustomer(
        baseProduct?.prices || [],
        customer?.customer as Partial<Customer>,
      );

      // Get the total duration
      const totalDuration = baseProduct?.totalDuration || 0;

      // Update the current product
      const updatedProduct = {
        ...currentProduct,
        id: undefined,
        bookedPrice,
        requiredResources: [],
        allocation: [],
        totalDuration,
      };

      // Create a new products list with the updated product
      const updatedProductsList = form?.getFieldValue("products") || [];
      const productIndexToUpdate = updatedProductsList.findIndex(
        (p: AppointmentProductInput) => p.id === currentProduct.id,
      );
      updatedProductsList[productIndexToUpdate] = updatedProduct;

      // Update the form with the new products list
      form?.setFieldsValue({
        ...form?.getFieldsValue(),
        products: updatedProductsList,
      });

      // Recalculate the total price
      handleTotalPrice();

      // Validate vacancies
      await validateVacancies({
        values: form?.getFieldsValue(),
        appointmentId: id,
        index: productIndexToUpdate,
        restore: true,
      });

      // Update the appointment duration
      handleAppointmentDuration();
    },
    [form, handleTotalPrice, validateVacancies, id, handleAppointmentDuration],
  );

  return (
    <Form.List
      name="products"
      rules={[
        {
          validator: (_, value) => {
            if (value && value.length > 0) {
              return Promise.resolve();
            }
            return Promise.reject(
              new Error("Mindestens eine Leistung muss angegeben werden"),
            );
          },
        },
      ]}
    >
      {(fields, _, { errors }) => {
        const handleRemove = async (name: number) => {
          const product = form?.getFieldValue(["products", name]);

          const dependentProducts = getDependentProducts({
            products: form?.getFieldValue("products"),
            currentId: product.id,
          });

          removeAssociatedPackageProducts({
            appointmentProducts: form?.getFieldValue(["products"]),
            targetId: product.id,
            filterTarget: true,
          });

          // Recalculate the total price
          handleTotalPrice();

          if (dependentProducts.length) {
            const products = form?.getFieldValue("products") || [];
            const updatedProducts = products.map(
              (p: AppointmentProductInput) => {
                if (dependentProducts.some((dp) => dp.id === p.id)) {
                  return {
                    ...p,
                    id: undefined,
                  };
                }
                return p;
              },
            );
            // Validate vacancies
            await validateVacancies({
              values: {
                ...form?.getFieldsValue(),
                products: updatedProducts,
              },
              appointmentId: id,
              index: name,
              restore: false,
            });
          }
          // Update the appointment duration
          handleAppointmentDuration();
        };

        return (
          <>
            {fields.map(({ key, name, ...restField }) => {
              const isPackageProduct = formProducts?.[name]?.packageId || false;
              const isMainProduct = !formProducts?.[name]?.packageId || false;

              const isLastPackageProduct =
                formProducts?.length &&
                (!formProducts[name + 1] || !formProducts[name + 1]?.packageId);

              return (
                <Form.Item noStyle key={`Product_${key}`} shouldUpdate>
                  {({ getFieldValue }) => {
                    if (!getFieldValue("products")) return null;

                    return (
                      <div
                        style={{
                          width: "100%",
                          margin: 0,
                          marginTop: isMainProduct ? 10 : 0,
                          paddingLeft: 10,
                          paddingRight: 10,
                          paddingTop: isPackageProduct ? 0 : 10,
                          paddingBottom: isPackageProduct ? 0 : 10,
                          position: "relative",
                          borderTopLeftRadius: isPackageProduct ? 0 : 10,
                          borderTopRightRadius: isPackageProduct ? 0 : 10,
                          borderBottomLeftRadius: isLastPackageProduct ? 10 : 1,
                          borderBottomRightRadius: isLastPackageProduct
                            ? 10
                            : 0,
                          backgroundColor: "#f0f2f5",
                        }}
                        key={key}
                      >
                        <Button
                          hidden={isPackageProduct}
                          style={{
                            zIndex: 999,
                            position: "absolute",
                            top: 10,
                            right: 10,
                          }}
                          disabled={appointmentExpired()}
                          danger
                          size="small"
                          onClick={async () => {
                            await handleRemove(name);
                          }}
                          icon={<DeleteOutlined />}
                        />
                        <Row
                          hidden={isPackageProduct}
                          style={{
                            width: "100%",
                            margin: 0,
                          }}
                        >
                          <Col {...colSmall}>
                            <Form.Item
                              style={{
                                marginBottom: 5,
                              }}
                              {...restField}
                              {...formItemProps(
                                [name, "productId"],
                                "Leistung",
                                true,
                              )}
                            >
                              <AppointmentProductSelect
                                filter={{
                                  isSubProduct: false,
                                  nextVersionId: null,
                                  "stores.id": storeId,
                                }}
                                products={form?.getFieldValue("products")}
                                onChange={(value) =>
                                  handleChangeProduct(value, name, "productId")
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col {...colSmall}>
                            <Form.Item
                              hidden={isPackageProduct}
                              style={{
                                marginBottom: 5,
                              }}
                              {...restField}
                              {...formItemProps(
                                [name, "variationId"],
                                "Variation",
                                false,
                              )}
                            >
                              <VariationSelect
                                filter={
                                  form?.getFieldValue([
                                    "products",
                                    name,
                                    "productId",
                                  ])
                                    ? {
                                        "product.id": form?.getFieldValue([
                                          "products",
                                          name,
                                          "productId",
                                        ]),
                                      }
                                    : undefined
                                }
                                onChange={async (value) => {
                                  await handleChangeProduct(
                                    value,
                                    name,
                                    "variationId",
                                  );
                                }}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row
                          hidden={isPackageProduct}
                          style={{
                            width: "100%",
                            margin: 0,
                          }}
                        >
                          <Col span={24}>
                            <Form.Item
                              style={{
                                marginBottom: 5,
                              }}
                              {...restField}
                              {...formItemProps(
                                [name, "bookedPrice"],
                                "Gebuchter Preis",
                                true,
                              )}
                            >
                              <InputNumber
                                precision={2}
                                style={{ width: "100%" }}
                                onChange={() => handleTotalPrice()}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            span={24}
                            style={{
                              textAlign: "center",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <AllocationsConfigList id={id} name={name} />
                          </Col>
                        </Row>
                      </div>
                    );
                  }}
                </Form.Item>
              );
            })}
            <Form.Item
              style={{
                marginTop: 10,
              }}
            >
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                Leistung hinzufügen
              </Button>
              <Form.ErrorList errors={errors} />
            </Form.Item>
          </>
        );
      }}
    </Form.List>
  );
};

export default AppointmentProductsList;
