import { Checkbox, Col, Row } from "antd";
import { FilterExpression, where } from "@3ts/react-ant-crud";
import {
  ProductCategoryFragment,
  useGetProductCategoriesQuery,
} from "../../graphql/schema";

interface Props {
  value?: [number];
  onChange?: (
    value: number[],
    productIds: number[],
    variationIds: number[],
  ) => void;
  disabled?: boolean;
  filter?: FilterExpression<ProductCategoryFragment>;
}

const ProductCategoriesCheckboxGroup = ({
  value,
  onChange,
  filter = {},
  disabled,
}: Props) => {
  const { data } = useGetProductCategoriesQuery({
    fetchPolicy: "no-cache",
    variables: {
      options: {
        ...where(filter || {}),
        itemsPerPage: 10000,
      },
    },
  });

  const handleChangeProducts = (val: number[]) => {
    const productIds: number[] = [];
    const variationIds: number[] = [];
    val.forEach((v) => {
      const productCategory = data?.productCategories.items.find(
        (p) => p.id === v,
      );
      if (productCategory) {
        const ids = productCategory.products
          .filter((p) => p.available)
          .map((p) => {
            if (p.variations.length) {
              p.variations.map((variation) => variationIds.push(variation.id));
            }
            return p.id;
          });
        productIds.push(...ids);
      }
    });
    return { productIds, variationIds };
  };

  return (
    <Checkbox.Group
      disabled={disabled}
      style={{ width: "100%" }}
      onChange={(val) => {
        const { productIds, variationIds } = handleChangeProducts(val);
        if (onChange) onChange(val, productIds, variationIds);
      }}
      value={value}
    >
      <Row>
        {data?.productCategories.items
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((productCategories) => {
            return (
              <Col span={8} key={productCategories.id}>
                <Checkbox
                  disabled={disabled}
                  key={productCategories.id}
                  value={productCategories.id}
                >
                  {productCategories.name}
                </Checkbox>
              </Col>
            );
          })}
      </Row>
    </Checkbox.Group>
  );
};

export default ProductCategoriesCheckboxGroup;
