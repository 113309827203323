import React from "react";
import { DataTable } from "@3ts/react-ant-crud";
import { useNavigate } from "react-router-dom";
import {
  GetStoreListQuery,
  GetStoreListQueryVariables,
  ListStoreFragment,
  useGetStoreListLazyQuery,
} from "../graphql/schema";
import Content from "../components/layout/Content";

const Stores = () => {
  const navigate = useNavigate();

  return (
    <Content>
      <DataTable<
        ListStoreFragment,
        GetStoreListQuery,
        GetStoreListQueryVariables
      >
        id="stores"
        title="Filialen"
        singleItemTitle="Filiale"
        query={useGetStoreListLazyQuery}
        columns={{
          id: "ID",
          name: "Name",
          address: "Adresse",
          city: "Stadt",
        }}
        onClickRow={(item) => {
          navigate(`/store/${item.id}`);
        }}
        onClickCreate={() => {
          navigate("/createStore");
        }}
      />
    </Content>
  );
};

export default Stores;
