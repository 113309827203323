import {
  Button,
  Col,
  Drawer,
  Form,
  FormInstance,
  Input,
  Modal,
  Row,
} from "antd";
import React from "react";
import { DeleteOutlined } from "@ant-design/icons";
import formItemProps from "../../helper/form/formItemProps";
import ProductCategorySelect from "./ProductCategorySelect";

const { confirm } = Modal;

interface Props {
  form: FormInstance;
  id: number | null;
  open: boolean;
  onClose: () => void;
  onDelete: (id: number) => Promise<void>;
  onCreate: (values: any) => Promise<void>;
  onUpdate: (id: number, values: any) => Promise<void>;
}

const ProductCategoryDrawer = ({
  form,
  open,
  id,
  onClose,
  onUpdate,
  onDelete,
  onCreate,
}: Props) => {
  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Drawer
      open={open}
      title={id ? "Kategorie bearbeiten" : "Kategorie erstellen"}
      width={400}
      onClose={handleClose}
      extra={
        id && (
          <Button
            icon={<DeleteOutlined />}
            danger
            onClick={async () => {
              confirm({
                type: "warning",
                title: "Wollen Sie diese Kategorie wirklich löschen?",
                content:
                  "Überprüfen Sie zunächst, ob diese Kategorie nicht in Leistungen verwendet wird. Andernfalls können Datenverluste auftreten.",
                onOk: async () => {
                  await onDelete(id);
                  window.location.reload();
                  handleClose();
                },
              });
            }}
          />
        )
      }
    >
      <Form layout="vertical" form={form}>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item {...formItemProps("name", "Name", true)}>
              <Input placeholder="Name" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              tooltip="Sie können eine Leistungskategorie einer anderen Leistungskategorie unterordnen. Wählen Sie hier die Hauptkategorie aus, zu der diese Leistungskategorie gehört. Wenn eine Leistungskategorie bereits Unterkategorien hat, kann sie nicht mehr einer anderen Kategorie untergeordnet werden."
              {...formItemProps("parentId", "Elternkategorie", false)}
            >
              <ProductCategorySelect
                disabled={form?.getFieldValue("children")?.length > 0}
                filter={{
                  parentId: null,
                  ...(id
                    ? {
                        id: {
                          _not: {
                            _eq: id,
                          },
                        },
                      }
                    : {}),
                }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            {id ? (
              <Button
                type="primary"
                onClick={async () => {
                  await onUpdate(id, form?.getFieldsValue());
                  handleClose();
                }}
              >
                Speichern
              </Button>
            ) : (
              <Button
                type="primary"
                onClick={async () => {
                  await onCreate(form?.getFieldsValue());
                  handleClose();
                }}
              >
                Erstellen
              </Button>
            )}
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
};

export default ProductCategoryDrawer;
