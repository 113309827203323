export type ThemeColor = "light" | "dark";
export const getThemeColor = (theme: ThemeColor) => {
  switch (theme) {
    case "light":
      return "#fff";
    case "dark":
      return "#000";
    default:
      return "#fff";
  }
};
