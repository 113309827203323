import { GqlErrorConstant } from "@app/errors/src/gql-error.constant";

type Props = {
  [key in GqlErrorConstant]?: (data: any) => void;
};

const handleGqlError = (
  e: any,
  handlers: Props & { default: (data: any) => void },
) => {
  const data = e.graphQLErrors?.[0]?.extensions;
  if (!data?.code) {
    throw e;
  }
  const handler: undefined | ((error: any) => void) =
    handlers[data.code as GqlErrorConstant];
  if (!handler) {
    handlers.default(data);
    return;
  }
  handler(data);
};

export default handleGqlError;
