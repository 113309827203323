import {
  Button,
  Card,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Row,
  Tabs,
} from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import React, { useCallback } from "react";
import { Dayjs } from "dayjs";
import formItemProps from "../../helper/form/formItemProps";
import {
  CreateVariationInput,
  CustomerType,
  ResourceType,
  UpdateVariationInput,
  useDeleteProductPriceMutation,
  useUpdateVariationMutation,
} from "../../graphql/schema";
import ResourceTypeSelect from "./ResourceTypeSelect";
import CustomerTypeSelect from "../customer/CustomerTypeSelect";
import ResourceRequirementSelect from "./ResourceRequirementSelect";
import StaticTimezoneDatePicker from "../staticTimezoneDatePicker/StaticTimezoneDatePicker";

interface Price {
  customerType: CustomerType;
  validFrom: Dayjs | undefined;
  validTo: Dayjs | undefined;
  price: string;
}

export interface FormFields {
  name: string;
  prices: Price[];
  requiredResources: {
    resourceType: ResourceType;
    resourceRequirements: {
      property: string;
      value: number[];
    }[];
  }[];
  totalDuration: number;
  productCategoryId: number;
  productId: number;
}

export const parseFormFields = (
  fields: FormFields,
): UpdateVariationInput & CreateVariationInput => {
  const requiredResources = fields.requiredResources || [];

  return {
    name: fields.name,
    prices:
      fields.prices?.map((price) => ({
        ...price,
        price: String(price.price),
      })) || [],
    requiredResources: requiredResources.map((resource) => ({
      resourceType: resource.resourceType,
      resourceRequirements: resource.resourceRequirements.map(
        (requirement, index) => ({
          property: requirement.property,
          value: requirement.value,
        }),
      ),
    })),
    totalDuration: fields.totalDuration,
    productId: fields.productId,
  };
};

const colSmall = {
  xs: 24,
  sm: 24,
  md: 24,
  lg: 12,
};

interface Props {
  id: number;
  formProps: any;
  disabled?: boolean;
}

const VariationForm = ({ formProps, id, disabled }: Props) => {
  const [removeProductPrice] = useDeleteProductPriceMutation();
  const { form } = formProps;

  const [update] = useUpdateVariationMutation();

  const handleSave = useCallback(
    async (fields: FormFields) => {
      try {
        await update({
          variables: {
            id,
            dto: parseFormFields(fields),
          },
        });
        message.success("Variation wurde gespeichert");
      } catch (e) {
        message.error("Variation konnte nicht gespeichert werden");
      }
    },
    [id],
  );

  const changeResourceType = useCallback(
    (value: any, name: any) => {
      form?.setFieldsValue({
        ...form?.getFieldsValue(),
        requiredResources: form
          ?.getFieldValue("requiredResources")
          .map((resource: any, index: number) =>
            index === name
              ? {
                  ...resource,
                  resourceRequirements:
                    value === ResourceType.Practitioner
                      ? [
                          ...resource.resourceRequirements,
                          {
                            property: "productCategoryId",
                            value:
                              form?.getFieldValue("productCategoryId") !==
                              undefined
                                ? [form?.getFieldValue("productCategoryId")]
                                : [],
                          },
                        ]
                      : [
                          {
                            property: "categoryId",
                            value: [],
                          },
                        ],
                }
              : resource,
          ),
      });
    },
    [form],
  );

  if (!form) return null;
  return (
    <Form {...formProps} layout="vertical" form={form}>
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="Allgemein" key="1" forceRender>
          <Row gutter={24}>
            <Col {...colSmall}>
              <Form.Item {...formItemProps("name", "Name", true)}>
                <Input disabled={disabled} />
              </Form.Item>
            </Col>
            <Col {...colSmall}>
              <Form.Item
                {...formItemProps("totalDuration", "Dauer in Minuten", true)}
              >
                <InputNumber
                  style={{
                    width: "100%",
                  }}
                  disabled={disabled}
                  step={5}
                  min={5}
                  max={1000}
                  parser={(value) => {
                    if (!value) return 5;
                    return (Math.round(Number(value) / 5) *
                      5) as unknown as number;
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Preise" key="2" forceRender>
          <Form.List
            name="prices"
            rules={[
              {
                validator: (_, value) => {
                  if (value && value.length > 0) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("Mindestens ein Preis muss angegeben werden"),
                  );
                },
              },
            ]}
          >
            {(fields, { add, remove }, { errors }) => {
              const handleAdd = () => {
                add({
                  customerType: CustomerType.Standard,
                  validFrom: undefined,
                  validTo: undefined,
                  price: 0,
                });
              };

              const handleRemove = async (index: number) => {
                const priceId = form?.getFieldValue("prices")[index]?.id;
                if (priceId) {
                  await removeProductPrice({
                    variables: {
                      id: priceId,
                    },
                  });
                }
                remove(index);
              };
              return (
                <>
                  {fields.map(({ key, name, ...restField }) => {
                    return (
                      <Row gutter={10} align="middle" key={`prices_${key}`}>
                        <Col span={6}>
                          <Form.Item
                            {...formItemProps(
                              [key, "customerType"],
                              "Kundengruppe",
                              true,
                            )}
                          >
                            <CustomerTypeSelect disabled={disabled} />
                          </Form.Item>
                        </Col>
                        <Col span={5}>
                          <Form.Item
                            {...restField}
                            {...formItemProps(
                              [key, "validFrom"],
                              "Gültig ab",
                              false,
                            )}
                          >
                            <StaticTimezoneDatePicker
                              format="DD.MM.YYYY"
                              style={{ width: "100%" }}
                              disabled={disabled}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={5}>
                          <Form.Item
                            {...restField}
                            {...formItemProps(
                              [key, "validTo"],
                              "Gültig bis",
                              false,
                            )}
                          >
                            <StaticTimezoneDatePicker
                              format="DD.MM.YYYY"
                              style={{ width: "100%" }}
                              disabled={disabled}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item
                            {...restField}
                            {...formItemProps(
                              [name, "price"],
                              "Preis in €",
                              true,
                            )}
                          >
                            <InputNumber
                              min={0}
                              max={100000}
                              precision={2}
                              disabled={disabled}
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                        </Col>
                        <Col
                          span={2}
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <MinusCircleOutlined
                            style={{
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            disabled={disabled}
                            onClick={() => handleRemove(name)}
                          />
                        </Col>
                      </Row>
                    );
                  })}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => handleAdd()}
                      block
                      disabled={disabled}
                      icon={<PlusOutlined />}
                    >
                      Preis hinzufügen
                    </Button>
                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                </>
              );
            }}
          </Form.List>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Ressourcen" key="3" forceRender>
          <Form.List
            name="requiredResources"
            rules={[
              {
                validator: async (_, requiredResources) => {
                  if (!requiredResources || requiredResources.length < 1) {
                    message.error(
                      "Es muss mindestens eine benötigte Ressource geben. Schaue im Tab 'Resourcen', ob die Anforderungen erfüllt sind.",
                    );
                    return Promise.reject(
                      new Error("Es muss mindestens eine Ressource geben"),
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            {(fields, { add, remove }, { errors }) => {
              const handleAdd = () => {
                add({
                  resourceType: "ROOM",
                  resourceRequirements: [
                    {
                      property: "categoryId",
                      value: [],
                    },
                  ],
                });
              };
              const handleRemove = (index: number) => {
                remove(index);
              };
              return (
                <Card title="Benötigte Ressourcen" key="resourceRequirements">
                  {fields.map(({ name, key, ...restField }) => {
                    return (
                      <Row
                        gutter={24}
                        key={`requiredRessources_${key}`}
                        align="middle"
                      >
                        <Col span={10}>
                          <Form.Item
                            {...restField}
                            {...formItemProps(
                              [name, "resourceType"],
                              "Ressourcentyp",
                              false,
                            )}
                          >
                            <ResourceTypeSelect
                              disabled={disabled}
                              onChange={(value) =>
                                changeResourceType(value, key)
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.List name={[name, "resourceRequirements"]}>
                            {(requirementFields) => {
                              return (
                                <Row gutter={20}>
                                  {requirementFields.map(
                                    ({
                                      key: requirementKey,
                                      name: requirementName,
                                    }) => (
                                      <Form.Item
                                        noStyle
                                        shouldUpdate
                                        key={`requirement_${requirementKey}`}
                                      >
                                        <ResourceRequirementSelect
                                          name={name}
                                          disabled={disabled}
                                          requirementName={requirementName}
                                        />
                                      </Form.Item>
                                    ),
                                  )}
                                </Row>
                              );
                            }}
                          </Form.List>
                        </Col>
                        <Col
                          span={2}
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <MinusCircleOutlined
                            style={{
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            onClick={() => handleRemove(name)}
                          />
                        </Col>
                      </Row>
                    );
                  })}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => handleAdd()}
                      block
                      disabled={disabled}
                      icon={<PlusOutlined />}
                    >
                      Ressourcen hinzufügen
                    </Button>
                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                </Card>
              );
            }}
          </Form.List>
        </Tabs.TabPane>
      </Tabs>
      <Row
        gutter={10}
        justify="end"
        style={{
          marginTop: 20,
        }}
      >
        <Col>
          <Button
            type="primary"
            disabled={disabled}
            onClick={() => handleSave(form.getFieldsValue())}
          >
            Speichern
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default VariationForm;
