import React from "react";
import { GenericForm } from "@3ts/react-ant-crud";
import { useNavigate } from "react-router-dom";
import { Button, Form, Result } from "antd";
import dayjs from "dayjs";
import Loading from "../components/base/Loading";
import VariationForm from "../components/products/VariationForm";
import Content from "../components/layout/Content";
import {
  useDeleteVariationMutation,
  useGetVariationQuery,
} from "../graphql/schema";

interface Props {
  index: number;
  disabled?: boolean;
}

const Variation = ({ index, disabled }: Props) => {
  const form = Form.useFormInstance();
  const ids = Form.useWatch("variationIds", form);
  const id = ids?.length ? ids[index] : undefined;
  const productCategoryId = Form.useWatch("productCategoryId", form);
  const navigate = useNavigate();

  const { data, loading, refetch } = useGetVariationQuery({
    variables: {
      id: id || "",
    },
    skip: !id,
  });
  const [remove] = useDeleteVariationMutation();

  const handleDelete = async () => {
    await remove({
      variables: {
        id,
      },
    });
    form.setFieldsValue({ variationIds: ids?.filter((i) => i !== id) });
  };

  if (loading) return <Loading />;

  if (!data)
    return (
      <Result
        status="500"
        title="Ups..."
        subTitle="Etwas ist schief gelaufen"
        extra={
          <Button type="primary" onClick={() => navigate(-1)}>
            Zurück
          </Button>
        }
      />
    );

  return (
    <Content>
      <GenericForm
        title={`${data?.variation.name || "Variation"} ${
          data?.variation.id ? `ID:${data.variation.id}` : ""
        }`}
        singleItemTitle="Variation"
        onDelete={!disabled ? handleDelete : undefined}
        formRenderer={(formProps) => (
          <VariationForm formProps={formProps} id={id} disabled={disabled} />
        )}
        initialValues={{
          name: data?.variation.name,
          prices:
            data?.variation.prices?.map((price) => ({
              price: price.price,
              validTo: price.validTo ? dayjs(price.validTo) : undefined,
              validFrom: price.validFrom ? dayjs(price.validFrom) : undefined,
              customerType: price.customerType,
            })) || [],
          requiredResources:
            data?.variation.requiredResources?.map((resource) => ({
              resourceType: resource.resourceType,
              resourceRequirements: resource?.resourceRequirements,
            })) || [],
          totalDuration: data?.variation.totalDuration,
          productId: id,
          productCategoryId,
        }}
      />
    </Content>
  );
};

export default Variation;
