import { Button, Modal, Tooltip } from "antd";
import { useCallback, useState } from "react";
import { isToday } from "date-fns";
import { StopOutlined } from "@ant-design/icons";
import { AppointmentStatus } from "../../../../graphql/schema";
import { useAppointment } from "../context/AppointmentContext";
import prepareFormValues from "../../../../helper/form/prepareFormValues";

const { confirm } = Modal;

interface Props {
  tab: "new" | "blocker";
  onClick: (input: any) => Promise<void>;
}

const NoShowButton = ({ onClick, tab }: Props) => {
  const [loading, setLoading] = useState(false);
  const { appointment, formValues } = useAppointment();
  const showButton = useCallback(() => {
    if (
      appointment?.id &&
      isToday(new Date(appointment?.dateFrom)) &&
      appointment?.appointmentStatus === AppointmentStatus.Confirmed
    ) {
      return true;
    }
    return false;
  }, [appointment]);

  const handleClick = async () => {
    await onClick({
      input: prepareFormValues(formValues),
      extra: {
        id: appointment?.id,
        appointmentStatus: AppointmentStatus.NoShow,
      },
    });
  };

  if (tab !== "new") return null;
  if (!showButton()) return null;

  return (
    <Tooltip title="Kunde ist nicht erschienen">
      <Button
        style={{
          margin: 10,
          marginRight: 5,
          marginLeft: 5,
        }}
        icon={<StopOutlined />}
        loading={loading}
        onClick={async () => {
          confirm({
            title: "Kunde nicht erschienen",
            content:
              "Möchten Sie den Kunden wirklich als nicht erschienen markieren?",
            onOk() {
              setLoading(true);
              handleClick()
                .then(() => {
                  setLoading(false);
                })
                .catch(() => {
                  setLoading(false);
                });
            },
            onCancel() {},
          });
        }}
      />
    </Tooltip>
  );
};

export default NoShowButton;
