import { isAfter, isBefore } from "date-fns";
import {
  Customer,
  CustomerType,
  ProductPriceOutput,
} from "../../graphql/schema";

const getPricesForCustomerCategory = (
  prices: Partial<ProductPriceOutput>[],
  customerType: CustomerType,
): Partial<ProductPriceOutput>[] => {
  return prices.filter((price) => price.customerType === customerType);
};

const getActiveTemporaryPrice = (
  prices: Partial<ProductPriceOutput>[],
): Partial<ProductPriceOutput>[] => {
  return prices.filter((price) => {
    return (
      price.validTo &&
      price.validFrom &&
      isBefore(new Date(), new Date(price.validTo)) &&
      isAfter(new Date(), new Date(price.validFrom))
    );
  });
};

const getStandardPrice = (prices: Partial<ProductPriceOutput>[]): number => {
  const standardPrice = prices.find(
    (price) => !price.validTo && !price.validFrom,
  );
  return parseInt(standardPrice?.price || "0", 10);
};

const getPriceForCustomer = (
  prices: Partial<ProductPriceFragment>[],
  customer?: Partial<Customer> | undefined,
): number => {
  const pricesForCustomer = getPricesForCustomerCategory(
    prices,
    customer?.category || CustomerType.Standard,
  );
  const activeTemporaryPrice = getActiveTemporaryPrice(pricesForCustomer);

  if (activeTemporaryPrice.length > 0) {
    return parseInt(activeTemporaryPrice[0].price || "0", 10);
  }
  return getStandardPrice(pricesForCustomer);
};

export default getPriceForCustomer;
