import React, { useMemo } from "react";
import { Form } from "antd";
import formItemProps from "../../helper/form/formItemProps";
import { ResourceType } from "../../graphql/schema";
import PractitionerCategoriesSelect from "../practitioners/PractitionerCategoriesSelect";
import RoomCategoriesSelect from "../store/RoomCategoriesSelect";
import EquipmentCategoriesSelect from "../store/EquipmentCategoriesSelect";
import ProductCategoryTreeSelect from "./ProductCategoryTreeSelect";

interface Props {
  name: number;
  requirementName: number;
  disabled?: boolean;
}

const ResourceRequirementSelect = ({
  name,
  requirementName,
  disabled,
}: Props) => {
  const form = Form.useFormInstance();

  const type = Form.useWatch(["requiredResources", name, "resourceType"], form);

  const category = Form.useWatch(
    [
      "requiredResources",
      name,
      "resourceRequirements",
      requirementName,
      "property",
    ],
    form,
  );

  const label = useMemo(() => {
    if (type === ResourceType.Practitioner) {
      return "Behandlerkategorie";
    }
    if (type === ResourceType.Room) {
      return "Raumkategorie";
    }
    if (type === ResourceType.Equipment) {
      return "Gerätekategorie";
    }
    return "Kategorie";
  }, [type]);

  if (!type) return null;
  return type === ResourceType.Practitioner &&
    category === "productCategoryId" ? (
    <Form.Item
      tooltip="Wählen Sie eine Leistungskategorie aus, welche möglichst genau den benötigen Fähigkeiten des Behandlers entspricht."
      style={{
        width: "50%",
        paddingRight: 10,
      }}
      {...formItemProps(
        [requirementName, "value"],
        "Leistungskategorie",
        false,
      )}
    >
      <ProductCategoryTreeSelect disabled={disabled} />
    </Form.Item>
  ) : (
    <Form.Item
      style={{
        width: "50%",
        paddingRight: 10,
      }}
      {...formItemProps([requirementName, "value"], label, false)}
    >
      {type === ResourceType.Practitioner && (
        <PractitionerCategoriesSelect disabled={disabled} />
      )}
      {type === ResourceType.Room && (
        <RoomCategoriesSelect disabled={disabled} />
      )}
      {type === ResourceType.Equipment && (
        <EquipmentCategoriesSelect disabled={disabled} />
      )}
    </Form.Item>
  );
};

export default ResourceRequirementSelect;
