import React from "react";
import { DataTable } from "@3ts/react-ant-crud";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/pro-light-svg-icons";
import {
  CommunicationType,
  CustomerType,
  GetCustomerListQuery,
  GetCustomerListQueryVariables,
  ListCustomerFragment,
  useGetCustomerListLazyQuery,
} from "../graphql/schema";
import Content from "../components/layout/Content";

const Customers = () => {
  const navigate = useNavigate();
  return (
    <Content>
      <DataTable<
        ListCustomerFragment,
        GetCustomerListQuery,
        GetCustomerListQueryVariables
      >
        id="customer"
        title="Kunden"
        singleItemTitle="Kunden"
        query={useGetCustomerListLazyQuery}
        columns={{
          id: "ID",
          firstName: {
            label: "Vorname",
            render: (item) =>
              item.length > 30 ? `${item.substring(0, 30)}...` : item,
          },
          lastName: {
            label: "Nachname",
            render: (item) =>
              item.length > 30 ? `${item.substring(0, 30)}...` : item,
          },
          email: {
            label: "E-Mail",
            render: (item) =>
              item.length > 30 ? `${item.substring(0, 30)}...` : item,
          },
          category: {
            label: "Kategorie",
            render: (status) => {
              if (status === CustomerType.Standard) return "Standard";
              if (status === CustomerType.Premium) return "Premium";
              return "Unbekannt";
            },
          },
          preferredCommunicationType: {
            label: "Kommunikationskanal",
            render: (status) => {
              if (status === CommunicationType.Sms) return "SMS";
              if (status === CommunicationType.Phone) return "Telefon";
              if (status === CommunicationType.Email) return "E-Mail";
              if (status === CommunicationType.Whatsapp) return "Whatsapp";
              return "Unbekannt";
            },
          },
          cooperation: {
            label: "Kooperationspartner",
            render: (v) =>
              v ? (
                <FontAwesomeIcon icon={faCheck} />
              ) : (
                <FontAwesomeIcon icon={faTimes} />
              ),
          },
          termsAccepted: {
            label: "AGB",
            render: (v) =>
              v ? (
                <FontAwesomeIcon icon={faCheck} />
              ) : (
                <FontAwesomeIcon icon={faTimes} />
              ),
          },
        }}
        onClickCreate={() => {
          navigate("/createCustomer");
        }}
        onClickRow={(item) => {
          navigate(`/customer/${item.id}`);
        }}
      />
    </Content>
  );
};

export default Customers;
