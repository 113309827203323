import React, { useCallback, useMemo } from "react";
import { GenericForm, where } from "@3ts/react-ant-crud";
import { useNavigate, useParams } from "react-router-dom";
import { Button, message, Result } from "antd";
import dayjs from "dayjs";
import Loading from "../components/base/Loading";
import ProductForm, {
  FormFields,
  parseFormFields,
} from "../components/products/ProductForm";
import Content from "../components/layout/Content";
import {
  GetProductsDocument,
  GetProductsSelectDocument,
  GetStoresDocument,
  ProductFragment,
  useArchiveProductMutation,
  useCreateProductMutation,
  useGetProductQuery,
  useGetProductsQuery,
  useUpdateProductMutation,
} from "../graphql/schema";
import UsedProductModal from "../components/products/UsedProductModal";
import isSelfReferencing from "../helper/common/isSelfReferencing";

const Product = () => {
  const params = useParams();
  const navigate = useNavigate();

  const id = parseInt(params.id!, 10);

  const { data, loading, refetch } = useGetProductQuery({
    fetchPolicy: "no-cache",
    variables: {
      id,
    },
  });

  const { data: products } = useGetProductsQuery({
    fetchPolicy: "no-cache",
    variables: {
      options: {
        itemsPerPage: 1000,
        ...where<ProductFragment>({
          nextVersionId: null,
        }),
      },
    },
  });

  const [update] = useUpdateProductMutation();
  const [archive] = useArchiveProductMutation();
  const [create] = useCreateProductMutation();

  const initialValues = useMemo(() => {
    return {
      id,
      index: data?.product.index,
      name: data?.product.name,
      descriptionShort: data?.product.descriptionShort,
      descriptionLong: data?.product.descriptionLong,
      prices:
        data?.product.prices?.map((price) => ({
          price: price.price,
          validTo: price.validTo ? dayjs(price.validTo) : undefined,
          validFrom: price.validFrom ? dayjs(price.validFrom) : undefined,
          customerType: price.customerType,
        })) || [],
      taxRate: data?.product.taxRate,
      blockedBetweenTreatments: data?.product.blockedBetweenTreatments,
      picture: data?.product.picture,
      isPackage: data?.product.isPackage,
      isSubProduct: data?.product.isSubProduct,
      externalId: data?.product.externalId || "",
      package: [
        {
          packageProductIds:
            data?.product.packageProducts?.map((product) => product.id) || [],
          packageAmount: data?.product.packageAmount,
        },
      ].filter((p) => p.packageProductIds.length > 0),
      variationIds:
        data?.product?.variations?.map((variation) => variation.id) || [],
      packageProducts:
        data?.product.packageProducts?.map((packageProduct) => ({
          id: packageProduct.id,
          variationId: packageProduct.variation?.id,
          productId: packageProduct.product?.id,
        })) || [],
      packageAmount: data?.product.packageAmount,
      incompatibleProductIds:
        data?.product.incompatibleProducts?.map((product) => product.id) || [],
      productCategoryId: data?.product.productCategory?.id,
      requiredResources:
        data?.product.requiredResources?.map((resource) => ({
          resourceType: resource.resourceType,
          resourceRequirements:
            resource?.resourceRequirements?.map((r) => ({
              property: r.property,
              value: r.value,
            })) || [],
        })) || [],
      isGoae: data?.product.isGoae,
      slug: data?.product.slug,
      goae: data?.product.goae,
      available: data?.product.available,
      totalDuration: data?.product.totalDuration,
    };
  }, [data]);

  const handleSave = useCallback(
    async (fields: FormFields) => {
      if (fields.available && !fields.externalId) {
        message.error(
          "Die Leistung ist buchbar, hat aber keine externe ID. Bitte trage eine externe ID ein.",
        );
        return;
      }
      if (
        isSelfReferencing({
          id: id ? Number(id) : null,
          product: {
            ...parseFormFields(fields),
            packageProducts:
              parseFormFields(fields).packageProducts?.map((p) => {
                return {
                  product: {
                    id: p.productId,
                  },
                };
              }) || [],
          },
          products: products?.products.items || [],
        })
      ) {
        message.error("Die Leistung kann nicht auf sich selbst verweisen.");
        return;
      }
      if (
        JSON.stringify(initialValues?.packageProducts) ===
          JSON.stringify(fields.packageProducts) &&
        initialValues?.totalDuration === fields.totalDuration &&
        JSON.stringify(initialValues?.requiredResources) ===
          JSON.stringify(fields.requiredResources) &&
        JSON.stringify(initialValues?.variationIds) ===
          JSON.stringify(fields.variationIds)
      ) {
        await update({
          variables: {
            id,
            dto: {
              ...parseFormFields(fields),
            },
          },
          refetchQueries: [GetProductsDocument],
        });
      } else {
        const product = await create({
          variables: {
            dto: {
              previousVersionId: id,
              ...parseFormFields(fields),
              storeIds: data?.product.stores.map((store) => store.id),
            },
          },
        });
        await archive({
          variables: {
            id,
            dto: {
              nextVersionId: product.data?.createProduct.id,
              ...parseFormFields(fields),
            },
          },
          refetchQueries: [
            GetProductsDocument,
            GetStoresDocument,
            GetProductsSelectDocument,
          ],
        });
        navigate(`/product/${product?.data?.createProduct.id}`);
        window.location.reload();
      }
      refetch();

      message.success("Die Änderungen wurden gespeichert.");
    },
    [id, data, initialValues, products],
  );

  const handleNextVersion = useCallback(async () => {
    const nextVersionId = data?.product.nextVersionId;
    if (nextVersionId) {
      navigate(`/product/${nextVersionId}`);
      window.location.reload();
    }
  }, [data]);

  const handlePreviousVersion = useCallback(async () => {
    const previousVersionId = data?.product.previousVersionId;
    if (previousVersionId) {
      navigate(`/product/${previousVersionId}`);
      window.location.reload();
    }
  }, [data]);

  if (loading) return <Loading />;

  if (!data)
    return (
      <Result
        status="500"
        title="Ups..."
        subTitle="Etwas ist schief gelaufen"
        extra={
          <Button type="primary" onClick={() => navigate(-1)}>
            Zurück
          </Button>
        }
      />
    );

  return (
    <Content>
      <GenericForm
        title="Leistung bearbeiten"
        singleItemTitle="Leistung"
        formRenderer={(formProps) => (
          <ProductForm
            formProps={formProps}
            nextVersionId={data?.product?.nextVersionId}
          />
        )}
        onBack={() => {
          navigate("/products");
        }}
        extra={[
          data?.product?.previousVersionId && (
            <Button
              key="previousVersion"
              disabled={!data?.product?.previousVersionId}
              onClick={handlePreviousVersion}
            >
              Vorherige Version anzeigen
            </Button>
          ),
          data?.product?.nextVersionId && (
            <Button
              key="nextVersion"
              disabled={!data?.product?.nextVersionId}
              onClick={handleNextVersion}
            >
              Nächste Version anzeigen
            </Button>
          ),
        ]}
        onSave={!data?.product?.nextVersionId ? handleSave : undefined}
        initialValues={initialValues}
      />
      <UsedProductModal product={data.product} />
    </Content>
  );
};

export default Product;
